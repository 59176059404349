import React from "react";
import { Popover } from "@headlessui/react";
import ReactTooltip from "react-tooltip";
import { InformationCircleIcon } from "@heroicons/react/solid";
import styled from "styled-components";

const StyledReactTooltip = styled(ReactTooltip)`
  background-color: white !important;
  color: black !important;
  box-shadow: 0px 2px 20px lightgray;
  &:after {
    border-top-color: white !important;
  }
`;

function InfoDescription({ text, setIcon, name, styles }) {
  return (
    <div>
      {setIcon ? (
        <div>
          <InformationCircleIcon
            data-tip
            data-for={name}
            className="h-5 w-5 animate-pulse text-gray-500"
          />
          <StyledReactTooltip id={name} effect="solid" className="">
            <span>{text}</span>
          </StyledReactTooltip>
        </div>
      ) : (
        <>
          <span
            className="capitalize text-sm block truncate"
          ></span>

          <StyledReactTooltip id={name} effect="solid" className="">
            <span>{text}</span>
          </StyledReactTooltip>
        </>
      )}
    </div>
  );
}

export default InfoDescription;

export const setToLS = (key, value) => {
    window.localStorage.setItem(key, (value instanceof Object) ? JSON.stringify(value) : value);
}

export const getFromLS = key => {
    let value = window.localStorage.getItem(key);

    try{
        value = JSON.parse(value);
    }catch{}

    return value;
}
import React from 'react';

function ReportLayout1(props) {
    return (
        <div>
            Report Layout 1
        </div>
    );
}

export default ReportLayout1;
import React, {useState, useRef, useEffect} from 'react';
import { Editor } from "react-draft-wysiwyg";
import { EditorState,convertToRaw, ContentState} from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from "html-to-draftjs"

import { Field, useField, useFormikContext } from 'formik';

function RichText(props) {
  let {name} = props
  const props2 = useFormikContext();
  const [field, meta, { setValue, setTouched}] = useField({name});
  const [saved,saveDraft] = useState(false);

  const contentBlock = htmlToDraft(field?.value ?? "");
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)

  const [editorState, setEditorState] = useState(() =>
      EditorState.createWithContent(contentState)
  );
    const editor = useRef(null);

    function focusEditor() {
        editor.current.focus();
    }

    const fieldValueCleared = field.value?.length == 0

    useEffect(() => {
      setEditorState(EditorState.createWithContent(contentState));
    }, [fieldValueCleared])

    const editorStateChange = (() => {
      let timeoutFunc;
      let timeout = 300;

      return (editorState) => {
          
          if(timeoutFunc) clearTimeout(timeoutFunc);

          timeoutFunc = setTimeout(() => {
            let oContent = editorState?.getCurrentContent();
            console.log("oContent",oContent)
            if(!oContent) return;

            let content = draftToHtml(convertToRaw(oContent));
            if(field.value !== content && (content != "<p></p>\n") ){
              setValue(content)
            }
            
          }, timeout)

          setEditorState(editorState)
      }
  })()

    // const editorStateChange = (editorState) => {
    //     setEditorState(editorState)
    //     if(saved){
    //       saveDraft(false)
    //     }
    //   };
    
    //const htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    return (
        <div
        className='relative border border-gray-400 p-4 pb-9'>
       <Editor
        name={name}
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={editorStateChange}
        />

        {/* {!saved && (field.value !== htmlContent) && (htmlContent != "<p></p>\n") &&
        <button 
        onClick={() => {
          setValue(htmlContent)
          saveDraft(true);
        }}
        type="button" 
        className="absolute right-4 bottom-2 py-1 px-1.5 bg-gray-400 text-white text-xs rounded-md">Save Draft</button>
        } */}
      </div>
    );
}

export default RichText;
import React, { useState, Fragment, useMemo, useEffect, useContext } from "react";
import styled from "styled-components";
import {Accordion, AccordionHead, AccordionBody, AccordionItem} from './Accordion';
import uuid from "react-uuid";
import { Tab } from '@headlessui/react'
import classNames from "classnames";
import WithLoader from "./WithLoader";
import AppContext from "../contexts/AppContext";
import appFetch from "../appFetch"
import apiEndpoints from "../apiEndpoints"
import Spinner from "../loaders/Spinner";

const options = [
    {
        "name": "Active Tenders", 
        "badge":20,
        "options":[
            {
                "name":"Kano meat tender",
                "data":[
                    {
                        "id": "802d6ee-848c-fbd6-4b13-a53cf382f5c",
                        "active": false,
                        "name": "Submission count"
                    },
                    {
                        "id": "b477647-c858-3eb4-a043-8bc338a1ed",
                        "active": false,
                        "name": "Winning vendor"
                    },
                    {
                        "id": "d82afe-7dda-ef2-d565-e2f220416b03",
                        "active": false,
                        "name": "Highest Bid"
                    },
                    {
                        "id": "c7a75e5-e6bd-863c-2b04-8154a418d835",
                        "active": false,
                        "name": "End date"
                    }
                ]
            },
            {
                "name":"Abuja packaging tender",
                "data":[]
            },
            {
                "name":"Lagos rice tednder",
                "data":[]
            }
        ]
    },
    {
        "name": "Closed Tenders", 
        "badge":3,
        "options":[
            {
                "name":"Lagos meat tender",
                "data":[
                    "Submission count",
                    "Winning vendor",
                    "Highest Bid",
                    "End date",
                ]
            },
            {
                "name":"Kastina packaging tender",
                "data":[]
            },
            {
                "name":"Lokoja rice tednder",
                "data":[]
            }
        ]
    },
    {
        "name": "Closing Tenders", 
        "options":[
            {
                "name":"Ondo meat tender",
                "data":[
                    {
                        "id": "ea3ba4-e5-a547-5c4f-6a26bf01663e",
                        "active": false,
                        "name": "Submission count"
                    },
                    {
                        "id": "cf45804-8e1-c5e3-dc18-04a5ca0f8035",
                        "active": false,
                        "name": "Winning vendor"
                    },
                    {
                        "id": "f68002-8a7f-5a63-5a7e-3c1a6aadaf6",
                        "active": false,
                        "name": "Highest Bid"
                    },
                    {
                        "id": "08e1cb0-d7fa-d1a3-a3f0-56efd2ebb5",
                        "active": false,
                        "name": "End date"
                    }
                ]
            },
            {
                "name":"Imo packaging tender",
                "data":[]
            },
            {
                "name":"Benin rice tednder",
                "data":[]
            }
        ]
    },
]

const TabHead = styled.div`
    box-shadow: 0px 1px 2px rgb(0 0 0 / 15%)
`

function AddFavouriteItem({options}) {
    // const dataOptions = options.map(o => o.options);

    const selectedItems = {}
    const {context, setContext} = useContext(AppContext)
    const optionsWithId = useMemo(() => options.map(option => {
        //added id to data options to control the state of the save button
        option.data?.forEach(item => {
            item.id = uuid()
            item.code = option.code
            selectedItems[item.id] = item.selected
        })
        return option
    }), [options])
    const [saving,setSaving] = useState(false)

    const [dataOptions, setDataOptions] = useState(optionsWithId)
    // const [checkboxvalues, setCheckBoxValues] = useState(selectedItems)
    const [checkboxvalues, setCheckBoxValues] = useState(selectedItems)
    const [checkboxUpdates, setCheckboxUpdates] = useState([])

    console.log({checkboxvalues,dataOptions})

    const AddNewFavouriteItem = styled.button`
        background: ${({theme, disabled}) => disabled ? theme?.colors?.accent : theme?.colors?.primary};
        cursor: ${({theme, disabled}) => disabled ? "not-allowed" : "pointer"};
        color: ${({theme, disabled}) => disabled ? "#bbbbbb" : "#ffffff"};
    `

    const updateFavouriteItem = () => {

        if(saving) return;

        const optionsSelected = {};
        const allCheckBoxes = dataOptions.reduce((a,b) => {
            return a.concat(b.data ?? []);
        }, [])

        for(let [key,value] of Object.entries(checkboxvalues)){
            if(!value) continue;
            let item = allCheckBoxes.find(o => o.id == key);
            if(item instanceof Object){
                if(value){
                    optionsSelected[item.code] = (optionsSelected[item.code] ?? []).concat(item.value)
                }
            }
        }

        let json = {
            "_FAVOURITE":{
                "FAVOURITE":optionsSelected
                }
            }

        console.log("json",json);

        setSaving(true)
        let postData = {
            "jsonString": JSON.stringify([json]),
            "menuId": context?.menuId,
            "pageButtonActionId": null,
            "buttonAction": 'FAVOURITE' 
          }

        appFetch.post(apiEndpoints.menuAction, {
            "body":JSON.stringify(postData),
        }).then(res => {
            console.log("res", res)
            setCheckboxUpdates([]);
            setTimeout(() => {
                setContext(prev => ({...prev, pageConfig: res?.layout}))
            }, 700)
        })
        .catch(e => console.log(e.message))
        .finally(() => setSaving(false));
        }

        const updateOption = (code, item) => {
            let newCheckboxesUpdates = [...checkboxUpdates]
            let exists = newCheckboxesUpdates.findIndex(id => id == item.id) !== -1
            newCheckboxesUpdates = exists ? newCheckboxesUpdates.filter(id => id !== item.id) : [...newCheckboxesUpdates, item.id]
            setCheckboxUpdates([...newCheckboxesUpdates]);
            
            //item exists
            let newCheckboxes = {...checkboxvalues}
            newCheckboxes[item.id] = !newCheckboxes[item.id];
            setCheckBoxValues({...newCheckboxes});
    }

    const Badge = styled.span`
        width:18px;
        height:18px;
        font-size:.7em;
        color:${({theme, active}) => active ? "#ffffff" : "#BBBBBB"};
        background: ${({theme, active}) => active ? theme?.colors.primary : "#F3F3F3"}
    `
    return (
        <div>
            <h1 className="font-bold text-sm uppercase mb-3 -mt-3">
                Favourites
            </h1>
            <div className="mt-10">
                <Accordion>
                    {dataOptions.map(({name, code, data},idx1) => {
                        return (
                            <AccordionItem key={idx1}>
                            <AccordionHead>{name}</AccordionHead>
                            <AccordionBody>
                                <ul className="grid pb-3">
                                    {data?.map((item, idx3) => {
                                        let {id, name, selected} = item
                                        return (
                                        <li key={idx3} className="checkbox-style-1">
                                            <input 
                                            type="checkbox" 
                                            id={`${code}_${idx3}`} 
                                            checked={checkboxvalues[id]}
                                            onChange={(e) => updateOption(code, item)}/>
                                            <label htmlFor={`${code}_${idx3}`}>{name}</label>
                                        </li>
                                    )})}
                                </ul>
                            </AccordionBody>
                        </AccordionItem>
                        )
                    })}
                </Accordion>
            </div>
            <div className="py-5 grid place-content-center">
                <AddNewFavouriteItem 
                className='uppercase text-white auto font-semibold px-4 py-3 rounded w-40 inline-grid place-items-center' 
                disabled={checkboxUpdates.length == 0} 
                onClick={updateFavouriteItem}>
                    {saving ? <Spinner /> : <>save</>}
                </AddNewFavouriteItem>
            </div>
        </div>
    );
}

export default AddFavouriteItem
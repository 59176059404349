import React, {useEffect} from "react"
import {createPortal} from "react-dom"
import "../assets/styles/loader.css"


const ScreenOverlay = ({ children, zIndex }) => {
    useEffect(() => {
      const portalRoot = document.getElementById("portal-root");
      portalRoot.style.zIndex = zIndex || 999; // Use the provided zIndex prop if available, otherwise use a default value
    }, [zIndex]);
  
    return createPortal(<div className="screen-overlay">{children}</div>, document.getElementById("portal-root"));
  };

// const ScreenLoader = () => <ScreenOverlay><div className="sp sp-circle"></div></ScreenOverlay>

const ScreenLoader = () => <ScreenOverlay><div className="sp sp-wave h-7 w-7"></div></ScreenOverlay>

export {ScreenLoader,ScreenOverlay}
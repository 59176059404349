import moment from "moment"
import { formatNumber } from "./util/formatter"

window.mm = moment


export const defaults = (type) => { 
  switch(type) {
  case "number":
  case "money":
    return ''
  case "select":
    return null
  case "multiselect":
  case "upload":
    return []
  case "date":
  case "datetime":
    return (new Date)
  case "colorpicker":
    return "#000000"
  default:
    return ""
  }
}


export default (schema) => {
    let formInitialValues = {};
    let formDefaultValues = {};
    schema?.forEach(({options}) => {
        options?.forEach(({type, value, name}) => {
            if((/label/i).test(type)){ return; }

            formInitialValues[name] = (typeof value == "undefined") ? defaults(type) : ( type === "number" ? formatNumber(value) : value)
            formDefaultValues[name] = defaults(type)
        })
    })

    // console.log("formInitialValues",formInitialValues)
    return {formInitialValues, formDefaultValues};
}
import React from 'react';
import styled from 'styled-components';
import profilePicture from "../assets/images/user.png"


const ProfileImage = styled.div`
    width: 98px;
    height: 98px;
    border-radius: 144px;
`


function ProfileModal({options}) {
    let profileData = options[0];
    return (
        <div className='p-6'>
            <div className='mb-5'>
                <ProfileImage className='overflow-hidden m-auto'>
                    <img src={profilePicture} className="w-full h-full" />
                </ProfileImage>
            </div>
            <div className='grid grid-cols-2 gap-10'>
                {Object.keys(profileData ?? {}).map(str => (
                    <div className='inline-grid'>
                        <span className='text-gray-400 text-sm font-medium'>{str}</span>
                        <span className='font-medium text-gray-600 truncate'>{(profileData ?? {})[str]}</span>
                    </div>
                ))}
                {/* <div className='inline-grid'>
                    <span className='text-gray-400 text-sm font-medium'>Email</span>
                    <span className='font-medium truncate text-gray-600'>{email}</span>
                </div>
                <div className='inline-grid'>
                    <span className='text-gray-400 text-sm font-medium'>Phone</span>
                    <span className='font-medium text-gray-600'>{phone}</span>
                </div>
                <div className='inline-grid'>
                    <span className='text-gray-400 text-sm font-medium'>Location</span>
                    <span className='font-medium truncate text-gray-600'>{location}</span>
                </div> */}
            </div>
            {/* <div className='grid grid-cols-2 gap-10 bg-accent -mx-6 px-6 mt-10 py-2'>
                <div className='inline-grid'>
                    <span className='text-primary text-sm font-medium'>Bids Submitted</span>
                    <span className='font-medium text-gray-600'>23</span>
                </div>
                <div className='inline-grid'>
                    <span className='text-primary text-sm font-medium'>Bids Won</span>
                    <span className='font-medium truncate text-gray-600'>3</span>
                </div>
            </div> */}
            {/* <div className='mt-6 w-full'>
                <button className='capitalize block text-sm bg-primary text-white rounded px-4 h-12 m-auto'>
                    update profile
                </button>
            </div> */}
        </div>
    );
}

export default ProfileModal;
import React, {useContext, useEffect, useState} from 'react';
import api from "../endpoints"
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import FilterIcon from '../icons/FilterIcon';
import AppContext from '../contexts/AppContext'
// import EmptyRecord from './EmptyRecord';
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon"
import '../index.css';

const EmptyRecord  = () => {
   return ( <div className='grid place-content-center place-items-center h-80 bg-gray-100 rounded-sm mb-5'>
        <EmptyTransactionIcon  />
        <h2 className='font-semibold text-md'>No chart available</h2>
    </div>)
}

function ChartControl2({name,options}) {

    const {context} = useContext(AppContext)
    options = context?.chartControlOptions ?? options;

    const graphData = options[0];

    for(var i=0; i<graphData?.series?.length; i++){
        graphData.series[i] = {...graphData.series[i]};
    }

    const graphOptions = {
        yAxis: {
            title: {
                text: ''
            }
        },
        xAxis: {
            categories: [],
        },
        plotOptions: {
            series: {
                label: {
                    enabled: false
                },
            }
        },
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
            
            }]
        },
        ...graphData
    }

    console.log('graphOptions',graphOptions, options);


    return (
        <div>
             <div id="graph" className="mb-10">
                 {Object.keys(graphData?.xAxis ?? {}).length > 0 ?
                    <HighchartsReact
                    highcharts={Highcharts}
                    options={{...graphOptions, ...graphData}} 
                    containerProps={{ style: { width: "100%" } }}
                    />
                    :
                    <EmptyRecord />
                    }
             </div>
        </div>
    );
}

export default ChartControl2;
import * as React from "react";

const RecentIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="22"
    fill="none"
    viewBox="0 0 18 22"
    {...props}
  >
    <path
      stroke="inherit"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M6 16h3M1 3v16a2 2 0 002 2h12a2 2 0 002-2V7.342a2 2 0 00-.602-1.43l-4.44-4.342A2 2 0 0010.56 1H3a2 2 0 00-2 2v0zm5 9h6-6z"
    ></path>
    <path
      stroke="inherit"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M11 1v4a2 2 0 002 2h4"
    ></path>
  </svg>
);

export default RecentIcon;

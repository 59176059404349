import React from 'react';
import formatter from '../util/formatter';

function WalletBalanceStyle2({options}) {
    const {balance, label} = options;

    return (
        <div className="border grid py-2 px-4 border-gray-custom rounded-xl" style={{backgroundColor:"#aeaeae"}}> 
            <div className='uppercase color-primary text-sm'>{label}</div>
            <div className='text-md font-bold'>{balance}</div>
        </div>
    );
}

export default WalletBalanceStyle2;
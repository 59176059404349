import classNames from 'classnames';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import components from '.';
import apiEndpoints from '../apiEndpoints';
import appFetch from '../appFetch';
import { appendQueryToUri } from '../util/generalUtil';
import ExcelTemplate from './ExcelTemplate';
import ImportDialog from './ImportDialog';
import WithLoader from './WithLoader';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(
    Swal.mixin({
      customClass: {
        confirmButton:
          "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
        cancelButton:
          "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
      },
      buttonsStyling: false,
    })
  );


function GridAndFormStepperWrapper1({setLoading, children, name,layoutid, search}) {
    // let {children,formHidden,setFormHidden} = props
    // let formStepper = children[0]?.props?.children?.props
    // let savedRecords = children[1]?.props?.children?.props


    // const [formStepper,setFormStepper] = useState(children[0]?.props?.children?.props);
    // const [savedRecords,setSavedRecords] = useState(children[1]?.props?.children?.props);


    const formStepper = children[0]?.props?.children?.props
    const [savedRecords,setSavedRecords] = useState(children[1]?.props?.children?.props);
    const [edit,setEdit] = useState(null);
    const [cart, setCart] = useState(null);
    const [formHidden,setFormHidden] = useState(false);
    const [formStepperClone, setFormStepperClone] = useState(null);
    const [importDialogOpen,setImportDialogOpen] = useState(false)

    useEffect(() => {
        setFormHidden(true);
    }, [name])

    const editRecord = (item) => {
        let valuesToEdit = item.edit[0];
        console.log("values to edit", valuesToEdit);

        let formStepperClone = {...JSON.parse(JSON.stringify(formStepper)), button_action: "UPDATE"};
        formStepperClone?.schema.forEach(({options}) => {
            options?.forEach(o => {
                if(valuesToEdit[o.edit]){
                    o["value"] = o.type == "multiselect" ? JSON.parse(valuesToEdit[o.edit]) : valuesToEdit[o.edit];
                }
            })
        })

        // setState(prev => ({...prev, formStepper:{...formClone, button_action: "UPDATE"}}))
        // setFormStepper({...formClone, button_action: "UPDATE"})

        setFormStepperClone(formStepperClone)
        setEdit(valuesToEdit)
        setFormHidden(false);   
    }

    const updateRecord = (records) => {
        setSavedRecords(records)
        setFormHidden(true);
    }

    const {excel_template, name:templateName, target_id} = formStepper;
    const templateHeader = excel_template?.reduce( (a,b) => {
        let newObj = {...a};
        newObj[b.name] = "";
        return newObj;
    }, {});

    console.log("formStepper children",{templateName, templateHeader});

    const activeFormStepper = formStepperClone ?? formStepper

    const defaultComponent = !formHidden ? activeFormStepper : {...savedRecords};
    const defaultProps = !formHidden ? {...activeFormStepper, edit, updateRecord, carts:cart} : {...savedRecords, editRecord,search,updateRecord,layoutid};

    const setFormNew = () => {

        if(formHidden == true){
            setFormHidden(false)
        }

        setEdit(null);
        setFormStepperClone(null);
    }


    const exportData = () => {
    setLoading(true)
      let postData = {    
          "urlMenu": null,
          "layoutId": layoutid,
          "targetId": target_id,
          "target": null,
          "schemaId": null,
          "txnGroup": null,
          "parentDropValue":null,
          "drillId": null,
          "targetName":null,
          "targetType":null,
          "outputType":"DATASET"

        }
        let token = localStorage.getItem("token") ?? ""
        fetch(appendQueryToUri(apiEndpoints.downloadData,postData),{
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`
              }
          })
          .then(response => {
            if (!response.ok) throw new Error('Something went wrong! could not download file.') 
            return response.blob()
          })
          .then(blob => {
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${templateName}-${moment().format('YYYY-MM-DD_h:msa')}.xlsx`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a)
            URL.revokeObjectURL(url)
          })
          .catch(e => {
            MySwal.fire({
              text: e.message,
              icon: "error",
            });
          })
          .finally(e => setLoading(false))
    }

    

    // console.log({formStepper,savedRecords});
    return (
        <>
        {importDialogOpen && 
        <ImportDialog 
            templateName={templateName} 
            templateHeader={templateHeader} 
            isOpen={importDialogOpen} 
            targetId={target_id}
            closeModal={() => setImportDialogOpen(false)}
        />}
        <div class="relative">
             {formHidden ?
                <div className={classNames("flex gap-2", {"absolute right-0 sm:-top-10 -top-14":!["role", "codes","work flow"].includes(name.toLowerCase())})}>
                {templateHeader && <button 
                    className="bg-primary hover:bg-primary text-white tracking-normal font-semibold text-xs py-2 px-4 rounded inline-flex items-center"
                    onClick={() => setImportDialogOpen(true)}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 4 24 24" stroke-width="2.5" stroke="currentColor" width="18" height="18">
                        <path stroke='white' d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25" />
                    </svg>
                    <span className='uppercase ml-2 hidden lg:block'>Import</span>
                </button>}
                <button 
                    className="bg-primary hover:bg-primary text-white tracking-normal font-semibold text-xs py-2 px-4 rounded inline-flex items-center"
                    onClick={exportData}
                    >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 2 26 26" stroke-width="2.5"  width="18" height="18">
                        <path stroke="white" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                    </svg>
                    <span className='uppercase ml-2 hidden lg:block'>Export</span>
                </button>

                <button 
                className="bg-primary hover:bg-primary text-white tracking-normal font-semibold text-xs py-2 px-4 rounded inline-flex items-center"
                onClick={setFormNew}>
                    <svg width="18" height="18" viewBox="0 0 20 18" stroke-width="0.9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.42857 6.14286C8.42857 5.9913 8.48878 5.84596 8.59594 5.7388C8.7031 5.63163 8.84845 5.57143 9 5.57143C9.15155 5.57143 9.2969 5.63163 9.40406 5.7388C9.51123 5.84596 9.57143 5.9913 9.57143 6.14286V8.42857H11.8571C12.0087 8.42857 12.154 8.48878 12.2612 8.59594C12.3684 8.7031 12.4286 8.84845 12.4286 9C12.4286 9.15155 12.3684 9.2969 12.2612 9.40406C12.154 9.51123 12.0087 9.57143 11.8571 9.57143H9.57143V11.8571C9.57143 12.0087 9.51123 12.154 9.40406 12.2612C9.2969 12.3684 9.15155 12.4286 9 12.4286C8.84845 12.4286 8.7031 12.3684 8.59594 12.2612C8.48878 12.154 8.42857 12.0087 8.42857 11.8571V9.57143H6.14286C5.9913 9.57143 5.84596 9.51123 5.7388 9.40406C5.63163 9.2969 5.57143 9.15155 5.57143 9C5.57143 8.84845 5.63163 8.7031 5.7388 8.59594C5.84596 8.48878 5.9913 8.42857 6.14286 8.42857H8.42857V6.14286ZM17 9C17 13.4183 13.4183 17 9 17C4.58171 17 1 13.4183 1 9C1 4.58171 4.58171 1 9 1C13.4183 1 17 4.58171 17 9ZM15.8571 9C15.8571 5.21314 12.7869 2.14286 9 2.14286C5.21314 2.14286 2.14286 5.21314 2.14286 9C2.14286 12.7869 5.21314 15.8571 9 15.8571C12.7869 15.8571 15.8571 12.7869 15.8571 9Z" fill="white" stroke="white"/>
                    </svg>
                    <span className='uppercase ml-2 hidden lg:block'>{name}</span>
                </button>
                </div> :
                <div className="relative">
                <button 
                class={classNames("tracking-normal font-semibold text-xs py-2 px-2 rounded inline-flex items-center", {"absolute right-0 -top-10 mt-0.5":!["role", "codes","work flow"].includes(name.toLowerCase())})}
                onClick={() => {
                    setFormHidden(true)
                }}>
                    <svg width="12" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.46154 14L2 8L8.46154 2M2.89744 8H16" stroke="#aaaaaa" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span className="ml-1 text-black-400">Back</span>
                </button>
                </div>
            }

            <div className="record-with-form-wrapper mt-5">
                {defaultComponent?.type && React.createElement(
                    components[defaultComponent.type],
                    {...defaultProps}
                    )
                }
            </div>
       </div>
       </>
    );
}

export default WithLoader(GridAndFormStepperWrapper1);
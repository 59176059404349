import React, {useState, useEffect, useContext} from 'react';
import icons from '../icons';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, MenuAlt2Icon } from '@heroicons/react/solid'
import logos from "../logos"
import uuid from 'react-uuid'
import AppContext from '../contexts/AppContext';
import WithLoader from './WithLoader';
import appFetch from '../appFetch';
import apiEndpoints from '../apiEndpoints';
import { createBrowserHistory } from 'history';
import { appendQueryToUri } from '../util/generalUtil';
import LeftBarContext from '../contexts/LeftBarContext';
import styled from 'styled-components';
import { useHistory, useParams} from 'react-router-dom';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const LeftBarToggler = styled.div`
    width: 32px;
    height: 32px;
    background: #FCFCFC;
`

function ApplicationList1(props) {
    const { name, setLoading, selectedtargetid, selectedsubscriberid} = props
    let {options} = props
    const [activeBundle,setActiveBundle] = useState(null)
    const {context, setContext} = useContext(AppContext)
    const {menuCollapse,setMenuCollapse} = useContext(LeftBarContext)
    const history = useHistory()

    console.log('optionsss',options);

    console.log(props);
    

    const groupedData = options?.reduce((acc, option) => {
      const subscriber = option?.subscriber;
      if (!acc[subscriber]) {
        acc[subscriber] = [];
      }
      acc[subscriber].push(option);
      return acc;
    }, {});

     console.log('optionsGroup',groupedData);

     const [selectedSub, setSelectedSub] = useState(null);

      const TreeSub = (item) => {
        // console.log('Tree sub item', item.item);
        console.log(item);
        item=item.item;
        let {name, subscriber, icon} = item;
        // console.log('name', subscriber)
        return (
          <div className='py-1'>
          <Menu.Item>
            {({ active }) => {
              return (
                <button
                  onClick={() => handleApplicationChange(item)}
                  className={classNames(
                    active
                      ? "bg-gray-100 text-gray-900"
                      : "text-gray-700",
                    "px-4 py-2 text-sm flex items-center gap-2 w-full"
                  )}
                >
                  {icons[icon] && React.createElement(icons[icon])}
                  {/* <span className='uppercase'>{item.subscriber}</span> */}
                  <span className="uppercase pl-10">{name}</span>
                </button>
            )}}
          </Menu.Item>
          </div>
        )
      }

    useEffect(() => {
      // let defaultOption = (options || [])[0]
      let defaultOption = options?.find(m => m.targetid == (selectedtargetid || "") && m.subscriberid == (selectedsubscriberid || ""))
      console.log(selectedsubscriberid);
      console.log("defaultOption",defaultOption, selectedtargetid);
      if(defaultOption){
        setActiveBundle(defaultOption);
      }
    }, [selectedtargetid, selectedsubscriberid])

    console.log("activeBundle",activeBundle)
    const handleApplicationChange = item => {
      if(!item) return;
      setLoading(true)
      let postData = {    
          "urlMenu": null,
          "layoutId": item?.layoutid,
          "targetId": item?.targetid,
          "target": item?.target,
          "subscriberID" : item?.subscriberid,
          "schemaId": null,
          "txnGroup": null,
          "parentDropValue":null,
          "drillId": null,
          "targetName":null,
          "targetType":null,

        }

      appFetch.get(appendQueryToUri(apiEndpoints.menuView,postData)).then(res => {
          console.log("new application state -", res)
          const subscriberurl = item?.subscriberurl?.toLowerCase();
          // const isSubscriber = subscriberurl == subs
          console.log(history);
          history.push(`/${subscriberurl}/home`);
          setLoading(false)
          setTimeout(() => {
            setContext(prev => ({...prev,chartControlOptions:null,theme:item?.theme,pageConfig:res}))
          },350)
      })
      .catch(e => console.log(e.message))
      //.finally(() => );
    }

    const iconMap = {
      "ThePlace":"ThePlaceLogo"
    }

    const activeIcon = iconMap[activeBundle?.icon] ?? ""

    return (
      <div className="mb-8">
        <div className="grid items-center h-16 lg:h-24">
          {menuCollapse && <LeftBarToggler className="hidden cursor-pointer md:inline-grid place-items-center" onClick={() => setMenuCollapse(!menuCollapse)}>
                     {activeBundle?.logo[0] ? <img src={activeBundle?.logo[0]} className="h-8 object-contain"/> : 
                      <div className="inline-grid place-items-center shadow rounded-full w-10 h-10 bg-accent">
                      <span className="font-bold text-xl text-primary">{name?.substring(0,1)}</span>
                      </div>}
              </LeftBarToggler>
          }
          {!menuCollapse && <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="ml-2 lg:ml-0 inline-flex items-center justify-between w-full rounded-md 0 px-0 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
                {activeBundle && (
                  <div className="flex items-center gap-2">
                    {Array.isArray(activeBundle?.logo) && (
                      <img src={activeBundle?.logo[0]} className="h-8 w-12 object-contain"/>
                    )}
                    <div className="grid place-items-start">
                      <span className="text-main-black capitalize font-black tracking-wide text-base">
                        {name.toLowerCase()}
                      </span>
                      <span
                        className="capitalize font-medium tracking-wide text-xs"
                        style={{ color: "#838383" }}
                      >
                        {activeBundle?.name.toLowerCase()}
                      </span>
                    </div>
                  </div>
                )}
                <ChevronDownIcon
                  className="-mr-1 ml-2 h-5 w-5"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute transform left-1/2 -translate-x-1/2 mt-2 w-80 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                {options &&
                options.filter(({ subscriber, name }) => activeBundle?.subscriber !== subscriber || activeBundle?.name !== name)
                  .reduce((groupedData, item) => {
                    const existingGroup = groupedData.find(group => group.subscriber === item.subscriber);
                    console.log(activeBundle);

                    if (existingGroup) {
                      existingGroup.items.push(item);
                    } else {
                      groupedData.push({
                        subscriber: item.subscriber,
                        items: [item],
                        logo: item.logo,
                      });
                    }
                    console.log(existingGroup);
                    console.log(groupedData)
                    return groupedData;
                  }, [])
                  .map((group, idx) => (
                    
                    <React.Fragment key={idx}>
                      <Menu.Item
                      onClick={(e) => {
                          e.preventDefault();
                        }
                      }>
                        {({ active }) => (
                          <div>
                            <button
                              onClick={() => setSelectedSub(group?.subscriber)}
                              className={classNames(
                                active ? "text-gray-900" : "text-gray-700",
                                "px-4 py-2 text-sm flex items-center gap-2 w-full"
                              )}
                            >
                               {Array.isArray(group?.logo) && (
                              <img src={group?.logo[0]} className="h-8 w-6 object-contain"/>)}
                              {icons[group.items[0].icon] && React.createElement(icons[group.items[0].icon])}
                              <span className="uppercase ">{group.subscriber}</span>
                            </button>
                          </div>
                        )}
                      </Menu.Item>
                      {((selectedSub)===(group.subscriber)) && group.items.map((item, itemIdx) => (
                        <TreeSub key={itemIdx} item={item} />
                      ))}<hr/>
                    </React.Fragment>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          }
        </div>
        {!menuCollapse && <hr className="-mx-8" />}
      </div>
    );
}

export default WithLoader(ApplicationList1);
import { useSerialIds } from 'highcharts';
import React, {useContext, useEffect, useState } from 'react';
import appFetch from '../appFetch';
import apiEndpoints from '../apiEndpoints';
import styled from 'styled-components';
import {Formik, Form as FormikForm,Field,ErrorMessage} from "formik"
import { LockClosedIcon, UserIcon,MailIcon } from '@heroicons/react/solid'
import Spinner from "../loaders/Spinner"
import * as Yup from "yup"

import doubleBubbleBackground from "../assets/images/double-bubble-outline.png"
import LoginLogo from "../assets/images/login-logo.png"
import ReactJson from 'react-json-view';
import { setToLS } from '../util/storage';
import { Link, useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import AppContext from '../contexts/AppContext';
import WithThemeWrapper from '../theme/WithThemeWrapper';


const LoginContainer = styled.div`
    background:#ffffff;
`

const LoginArea = styled.div`
    &:before,
    &:after{
        content: "";
        position: absolute;
        width:100%;
        height:100%;
        z-index:-2;
    }
    &:before{
        background: #FDF9EB;
    }
    &:after {
        background: url(${doubleBubbleBackground});
        background-blend-mode: multiply;
        opacity: 0.25;
        z-index:-1;
      }
`

function ResetPassword({theme, subs}) {
    const [processing,setProcessing] = useState(false);
    const history = useHistory();
    const {context,setContext} = useContext(AppContext)
    const logo = (theme?.logo || [])[0]

    const initialValues = {
        "password": "",
        "confirmPassword":""
    }
    // const validationSchema = Yup.object().shape({
    //     userName: Yup.string()
    //       .required('Username is required!'),
    //     password: Yup.string()
    //       .min(4, 'Password has to be longer than 6 characters!')  
    //       .required('Password is required!')
    //   })

      const validationSchema = Yup.object().shape({
        password: Yup.string()
        .min(4, 'Password has to be longer than 6 characters!')  
        .required("This field is required"),
        confirmPassword: Yup.string()
        .required("This field is required")
        .when("password", {
          is: val => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "password mismatch."
          )
        })
      });

      const postData = (values) => new Promise((resolve, reject) => {
        setProcessing(true)
        appFetch.post(`${apiEndpoints.changePassword}?subs=${subs}`, {
            body : JSON.stringify({...values}),
            headers:{
                "Authorization":`Bearer ${context?.token}`,
                "Content-Type":"application/json"
            }
        }).then(res => {
            console.log('value of response is', res)
            resolve(res);
        }).catch(err => reject(err))
        .finally(() => setProcessing(false)) 
      })

    return (
            <LoginArea className='min-h-screen h-full flex flex-col items-center justify-center'>
                <div className="max-w-md w-full space-y-8 -mt-5 px-4">
                    <div>
                        <img
                        className="mx-auto h-12 w-auto"
                        src={logo}
                        alt={theme?.name}
                        />
                        {/* <h2 className="mt-2 text-center text-2xl font-extrabold text-custom-black-1000">Admin Portal</h2> */}
                    </div>
                    <LoginContainer className="rounded-md shadow-md py-6 px-4 sm:px-6 lg:px-8">
                        <h2 className="text-center text-2xl font-medium text-custom-black-100">Reset Password</h2>
                        {/* <p className="mt-2 text-center text-sm">
                            <a href="#" className="font-normal text-custom-gray-200 hover:text-gray-300">
                                Enter your username and password
                            </a>
                        </p> */}
                        <Formik 
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                toast.promise(postData({password:values.password}), {
                                    loading: 'Processing',
                                    success: (res) => {
                                        setTimeout(() => {
                                            setToLS("token", res.token)
                                            setToLS("loginUrl", res.loginUrl)
                                            let link = `/${subs}/home`
                                            history.push(link);
                                        }, 500)
                                        return 'Successful. redirecting...'
                                    },
                                    error: (err) =>  err?.message ?? "failed! try again." ,
                                });
                            }}
                        >
                            {(props) => {
                                return (
                                <FormikForm className='mt-8 space-y-6'>
                                <input type="hidden" name="remember" defaultValue="true" />
                                <div className='mb-2'>
                                    <label htmlFor="password" className="relative text-gray-400 focus-within:text-gray-600 block">
                                        <span className="absolute left-0 inset-y-0 flex items-center pl-3 z-10">
                                            <LockClosedIcon className="h-5 w-5" style={{color: theme?.colors?.primary}} aria-hidden="true" />
                                        </span>
                                        <Field
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="off"
                                        className="pl-9 placeholder-custom-gray-100 placeholder-opacity-40 appearance-none relative block w-full px-3 py-2 border border-custom-gray-200 text-gray-500 rounded-md focus:outline-none sm:text-sm"
                                        placeholder="password"
                                        />
                                    </label>
                                    <ErrorMessage name="password" render={msg => <div className="text-xs text-red-600 mt-1">{msg}</div>} />
                                </div>
                                <div className='mb-1'>
                                    <label htmlFor="password" className="relative text-gray-400 focus-within:text-gray-600 block">
                                        <span className="absolute left-0 inset-y-0 flex items-center pl-3 z-10">
                                            <LockClosedIcon className="h-5 w-5" style={{color: theme?.colors?.primary}} aria-hidden="true" />
                                        </span>
                                        <Field
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        type="password"
                                        autoComplete="off"
                                        className="pl-9 placeholder-custom-gray-100 placeholder-opacity-40 appearance-none relative block w-full px-3 py-2 border border-custom-gray-200 text-gray-500 rounded-md focus:outline-none sm:text-sm"
                                        placeholder="confirm password"
                                        />
                                    </label>
                                    <ErrorMessage name="confirmPassword" render={msg => <div className="text-xs text-red-600 mt-1">{msg}</div>} />
                                </div>

                                <button
                                    type="submit"
                                    disabled={processing}
                                    className="group uppercase relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white"
                                    style={{backgroundColor: theme?.colors?.primary}}
                                >
                                    {processing ? <Spinner /> : <>
                                        Reset
                                    </>}
                                </button>  

                                <div className="flex items-center justify-center">
                                    <div className="text-sm text-custom-gray-200">
                                        Return to
                                        <Link to="login" className="font-medium" style={{color:theme?.colors?.accent}}>
                                            &nbsp;Login
                                        </Link>
                                    </div>
                                </div>                       
                                </FormikForm>
                            )}}
                    </Formik>   
                    </LoginContainer>
                </div>
            </LoginArea>
    );
}

export default WithThemeWrapper(ResetPassword);
import React, { useCallback, useEffect, useState } from "react";
import { utils, writeFileXLSX } from 'xlsx';

function ExcelTemplate({templateName, templateHeader}) {
    const [pres, setPres] = useState([templateHeader]);
    const normalizedName = templateName?.toLowerCase().replace(/\s+/g,"-");
  
    /* get state data and export to XLSX */
    const exportFile = useCallback(() => {
      const ws = utils.json_to_sheet(pres);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFileXLSX(wb, `${normalizedName}.xlsx`);
    }, [pres]);
  
    return (
        <button className="bg-primary hover:bg-primary text-white tracking-normal font-semibold text-xs py-2 px-4 rounded inline-flex items-center"
            onClick={exportFile}
        >
        <span className='capitalize hidden lg:block text-xs'>Download Template</span>
        </button>
    )
}

export default ExcelTemplate;
import { useSerialIds } from 'highcharts';
import React, {useEffect, useState, useContext } from 'react';
import appFetch from '../appFetch';
import apiEndpoints from '../apiEndpoints';
import styled from 'styled-components';
import {Formik, Form as FormikForm,Field,ErrorMessage} from "formik"
import { LockClosedIcon, UserIcon,MailIcon } from '@heroicons/react/solid'
import Spinner from "../loaders/Spinner"
import * as Yup from "yup"

import doubleBubbleBackground from "../assets/images/double-bubble-outline.png"
import LoginLogo from "../assets/images/login-logo.png"
import ReactJson from 'react-json-view';
import { setToLS } from '../util/storage';
import { Link, useHistory, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import AppContext from "../contexts/AppContext"
import WithThemeWrapper from '../theme/WithThemeWrapper';


const LoginContainer = styled.div`
    background:#ffffff;
`

const LoginArea = styled.div`
    &:before,
    &:after{
        content: "";
        position: absolute;
        width:100%;
        height:100%;
        z-index:-2;
    }
    &:before{
        background: #FDF9EB;
    }
    &:after {
        background: url(${doubleBubbleBackground});
        background-blend-mode: multiply;
        opacity: 0.25;
        z-index:-1;
      }
`

function ForgotPassword({theme, subs}) {
    const [processing,setProcessing] = useState(false);
    const history = useHistory();
    const {context,setContext} = useContext(AppContext)
    const [resetStatus,setResetStatus] = useState(null);
    const logo = (theme?.logo || [])[0]

    const initialValues = {
        "userName": "",
    }
    const validationSchema = Yup.object().shape({
        userName: Yup.string().emailOrPhone("not a valid email or phone number")
        .required('field is required!'),
      })

      const postData = (values) => new Promise((resolve, reject) => {
        setProcessing(true)
        setResetStatus(null);
        let {userName} = values
        userName = userName.includes("@") ? userName : Yup.string().appendCountryCode("+234").cast(userName);
        appFetch.post(`${apiEndpoints.forgotPassword}?subs=${subs}`, {
            body : JSON.stringify({...values, userName}) 
        }).then(res => {
            console.log('value of response is', res)
            resolve(res);
        }).catch(err => reject(err))
        .finally(() => 
           {
            setProcessing(false)
           }) 
      })

    return (
        <LoginArea className='min-h-screen h-full flex flex-col items-center justify-center'>
                <div className="max-w-md w-full space-y-8 -mt-5 px-4">
                    <div>
                        <img
                        className="mx-auto h-12 w-auto"
                        src={logo}
                        alt={theme?.name}
                        />
                        {/* <h2 className="mt-2 text-center text-2xl font-extrabold text-custom-black-1000">Admin Portal</h2> */}
                    </div>
                    <LoginContainer className="rounded-md shadow-md py-6 px-4 sm:px-6 lg:px-8">
                        <h2 className="text-center text-2xl font-medium text-custom-black-100">Forgot password</h2>
                        <p className="mt-2 text-center text-sm">
                            <a href="#" className="font-normal text-custom-gray-200 hover:text-gray-300">
                                Forgotten your password, don't worry, we’ll send you instructions to reset your password.
                            </a>
                        </p>
                        {resetStatus == "success" &&
                        <div class="mt-4 bg-green-100 border-t-4 border-green-500 rounded-b text-green-900 px-4 py-3 shadow-md" role="alert">
                            <div class="flex">
                                <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/></svg></div>
                                <div>
                                <p class="font-bold">Reset successful</p>
                                <p class="text-sm">Check your email or message for further instructions.</p>
                                </div>
                            </div>
                        </div>
                        }
                        <Formik 
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={(values) => {
                                // setTimeout(() => {
                                toast.promise(postData(values), {
                                    loading: 'Processing',
                                    success: (res) => {
                                        setResetStatus("success")
                                        return res?.message ?? 'check your email for further instructions'
                                    },
                                    error: (err) =>  err?.message ?? "password reset failed! try again." ,
                                });
                            }}
                        >
                            {(props) => {
                                return (
                                <FormikForm className='mt-8 space-y-6'>
                                <input type="hidden" name="remember" defaultValue="true" />
                                <div className='mb-2'>
                                    <label htmlFor="emailAddress" className="relative text-gray-400 focus-within:text-gray-600 block">
                                        <span className="absolute left-0 inset-y-0 flex items-center pl-3 z-10">
                                            <UserIcon className="h-5 w-5" style={{color: theme?.colors?.primary}} aria-hidden="true" />
                                        </span>
                                        <Field
                                        id="userName"
                                        name="userName"
                                        type="text"
                                        autoComplete="off"
                                        className="pl-9 placeholder-custom-gray-100 placeholder-opacity-40 appearance-none relative block w-full px-3 py-2 border border-custom-gray-200 text-gray-500 rounded-md focus:outline-none sm:text-sm"
                                        placeholder="email or phone number"
                                        />
                                    </label>
                                    <ErrorMessage name="userName" render={msg => <div className="text-xs text-red-600 mt-1">{msg}</div>} />
                                </div>

                                <button
                                    type="submit"
                                    disabled={processing}
                                    style={{backgroundColor: theme?.colors?.primary}}
                                    className="group uppercase relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white"
                                >
                                    {processing ? <Spinner /> : <>
                                        Continue
                                    </>}
                                </button>  

                                <div className="flex items-center justify-center">
                                    <div className="text-sm text-custom-gray-200">
                                        Back to
                                        <Link to="login" className="font-medium" style={{color:theme?.colors?.accent}}>
                                            &nbsp;Login
                                        </Link>
                                    </div>
                                </div>                       
                                </FormikForm>
                            )}}
                    </Formik>   
                    </LoginContainer>
                </div>
        </LoginArea>
    );
}

export default WithThemeWrapper(ForgotPassword);
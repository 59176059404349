import React, {useContext} from 'react';
import SearchIcon from '../icons/SearchIcon';
import {ThemeContext} from "styled-components";
import LoaderIcon  from '../icons/LoaderIcon';

function SearchControl1({children, handleSearch, search, searched}) {

    const theme = useContext(ThemeContext);
    return (
        <div className="search-bar border inline-flex items-center border-gray-custom w-full pl-3 rounded-xl mb-4">
            {searched || !search ? <SearchIcon fill="#333333" className="mr-3"/> : <LoaderIcon/> }
            <input type="text" className="text-sm rounded-xl h-12 w-full outline-none" placeholder="Search" onKeyUp={handleSearch}/>
        </div>
    );
}

export default SearchControl1;
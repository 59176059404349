import api from "./endpoints"
import uuid from 'react-uuid'
import formStepSchema from "./forms.json"
import formStepSchema2 from "./forms2.json"

import dashboardData from "./resources/dashboard.json" 
import setupData from "./resources/setup.json" 
import approvalsData from "./resources/approvals.json" 
import transactionData from "./resources/transaction.json" 
import openData from "./resources/open.json"
import memoData from "./resources/memo.json"

const bundle = {
    type: "ApplicationList1",
    options:[
        {
            "name":"accounting",
            "icon":"AccountingLogo"
        },
        {
            "name":"sales force",
        },
        {
            "name":"survey",
        },
        {
            "name":"dashboard",
        }
    ]
}

const walletBalance = {
    "type":"WalletBalanceStyle1",
    "options":{
        "balance":5596,
        "label":"Wallet Balance"
    }
}
const walletBalance2 = {
    "type":"WalletBalanceStyle2",
    "options":{
        "balance":5596,
        "label":"Wallet Balance"
    }
}
const appMenu = {
    type:"MenuList1",
    name:"menu",
    options: [
        {
            "name":"home",
            "icon":"HomeIcon",
        },
        {
            "name":"transaction",
            "icon":"CategoryIcon"
        },
        {
            "name":"setup",
            "icon":"CategoryIcon"
        },
        {
            "name":"approvals",
            "icon":"PaymentIcon"
        },
        {
            "name":"open",
            "icon":"PaymentIcon"
        },
        {
            "name":"report",
            "icon":"PaymentIcon"
        },
        {
            "name":"setting",
            "icon":"SettingIcon"
        },
    ]
}
const setupMenu = {
    type: "MenuList2",
    name:"setup",
    options: [
        {
            "name":"Account"
        },
        {
            "name":"Asset Category"
        },
        {
            "name":"Asset Group"
        },
        {
            "name":"Branch"
        },
        {
            "name":"Company"
        },
        {
            "name":"Conversion Logic"
        }
    ]
}
const searchControl1 = {
    type:"SearchControl1"
}
const addNewButton = {
    type: "ButtonStyle1",
    children:"New Transaction",
    order:"first",
    icon:"PlusRound"
}
const quickNotification = {
    type: "QuickNotificationList1",
    name:"quick notification",
    options: [
        {
            "name":"pending transaction",
            "value":"23",
            "icon":"PaymentIcon"

        },
        {
            "name":"open transaction",
            "value":"54",
            "icon":"GroupIcon"
        },
        {
            "name":"pending task",
            "value":"12",
            "icon":"UsersIcon"
        }
    ]

}
const graph = {
    type: "ChartControl1",
    name:"Expenses",
    dataSource : api.graphData
}

const graphData = [{
    name:"",
    color:"#CA5D99",
    data:[29.9, 42.5, 30.4, 50.2, 32.0, 46.0]
}];

const recentTransactions = {
    type: "RecentTransactionList1",
    name:"recent transactions",
    options: [
        {
            "id":"98766789",
            "date": (new Date).toISOString(),
            "desc": "<span>Temidayo <strong style='color:#55A55E'>Approved</strong> new expense request</span>",
            "status":"Approved",
            "amount":67950
        },
        {
            "id":"34567862",
            "date": (new Date).toISOString(),
            "desc": "<span>John Doe <strong style='color:#55A55E'>Submitted</strong> new opening balance</span>",
            "status":"Open",
            "amount":900000
        }
    ]
}

const favourite = {
    type:"FavouriteList1",
    name:"favourite",
    options:[
        {
            "name":"cash position",
            "value":5596
        },
        {
            "name":"stock position",
            "value":51250
        },
        {
            "name":"total expense",
            "value":10596
        },
        {
            "name":"total payables",
            "value":0
        },
        {
            "name":"dispatched",
            "value":8918
        }
    ]
}

// const addNewFavourite = {
//     "type":"Button",
//     "children":"Add New"
// }

const addNewFavourite = {

}

const appModule = {
    type:"MainModuleArea1",
    name:"New Transaction",
    options:[
        {
            name: "Assets",
            options : [
                {
                    name:"asset registration",
                    desc:"register an asset using a fixed asset register"
                },
                {
                    name:"asset depreciation",
                    desc:"run depreciation on asset in your fixed register"
                },
                {
                    name:"asset disposal",
                    desc:"write of/dispose of unused assets"
                },
                {
                    name:"asset revaluation",
                    desc:"carry out upward or downward revaluation of assets"
                }
            ]
        },
        {
            name: "Cash Management",
            options : [
            {
                name:"Cash Transfer",
                desc:"Transfer Cash"
            },
            {
                name:"Cash Balance",
                desc:"Balance your cash"
            }]
        },
        {
            name: "Purchases",
            options : []
        },
        {
            name: "Sales",
            options : []
        },
        {
            name: "Inbound Stock",
            options : []
        },
        {
            name: "Outbound Stock",
            options : []
        }
    ]
}

const schema = [
    {
        type: "date",
        label: "Date",
        required: true
    },
    {
        type: "select",
        label: "Account",
        required: true,
        options: [
            {
                label: "Cashier",
                value: "Cashier"
            },
            {
                label: "GT Current",
                value: "gt-current"
            }
        ]
    },
    {
        type: "text",
        label: "Remark",
        required: true
    }
]


const formStepper = {
    type:"FormStepper1",
    children:[ ...formStepSchema ].map(o => {
        o.schema = o.schema.map(s => {s.key = uuid(); return s})
        return o;
    })
}

const formStepper2 = {
    type:"FormStepper1",
    "name":"Cash Management > Cash Balance",
    children:[ ...formStepSchema2 ]
}

const quickHeaderMenu = {
    "type":"TopNavActionMenu1",
    "children": [
        {
            "type":"TopNavActionButton",
            "name":"",
            "icon":"NotificationIcon"
        },
        {
            "type":"TopNavActionButton",
            "name":"",
            "icon":"UserIcon"
        },
        {
            "type":"TopNavActionButton",
            "name":"",
            "icon":"MaleAvatar"
        }
    ]
}

const sales2 = {
    "type":"QuickNotificationModuleStyle1",
    "groups":[
        {
            "name":"Sales Invoice",
            "count":"2",
            "data":[
                {
                    "vendor":"Regression Vendor A",
                    "qty":5,
                    "amount":15000,
                    "items":[
                        {
                            "name":"Admin Internet",
                            "qty":2,
                            "amount":3000
                        },
                        {
                            "name":"Diesel",
                            "qty":3,
                            "amount":3000
                        }
                    ],
                    "actions":[
                        {
                            "type":"Pay"
                        },
                        {
                            "type":"Print"
                        }
                    ]
                },
                {
                    "vendor":"VOSP",
                    "qty":10,
                    "amount":1800000,
                    "items":[
                        {
                            "name":"Granite(Each)",
                            "qty":10,
                            "amount":18000
                        }
                    ],
                    "actions":[
                        {
                            "type":"Pay"
                        },
                        {
                            "type":"Invoice"
                        },
                         {
                            "type":"Receive"
                        },
                        {
                            "type":"Print"
                        }
                    ]
                },
                {
                    "vendor":"Regression Vendor A",
                    "qty":5,
                    "amount":50000,
                    "items":[
                        {
                            "name":"Admin Internet",
                            "qty":4,
                            "amount":10000
                        },
                        {
                            "name":"Diesel",
                            "qty":1,
                            "amount":30000
                        }
                    ],
                    "actions":[
                        {
                            "type":"Pay"
                        },
                        {
                            "type":"Print"
                        }
                    ]
                }
            ],

        },
        {
            "name":"Sales Order",
            count:1
        } 
    ],
}

const sales =  [
    {
        "name":"Sales Invoice",
        "count":"2",
        "data":[
            {
                "vendor":"Regression Vendor A",
                "qty":5,
                "amount":15000,
                "items":[
                    {
                        "name":"Admin Internet",
                        "qty":2,
                        "amount":3000
                    },
                    {
                        "name":"Diesel",
                        "qty":3,
                        "amount":3000
                    }
                ],
                "actions":[
                    {
                        "type":"Pay"
                    },
                    {
                        "type":"Print"
                    }
                ]
            },
            {
                "vendor":"VOSP",
                "qty":10,
                "amount":1800000,
                "items":[
                    {
                        "name":"Granite(Each)",
                        "qty":10,
                        "amount":18000
                    }
                ],
                "actions":[
                    {
                        "type":"Pay"
                    },
                    {
                        "type":"Invoice"
                    },
                     {
                        "type":"Receive"
                    },
                    {
                        "type":"Print"
                    }
                ]
            },
            {
                "vendor":"Regression Vendor A",
                "qty":5,
                "amount":50000,
                "items":[
                    {
                        "name":"Admin Internet",
                        "qty":4,
                        "amount":10000
                    },
                    {
                        "name":"Diesel",
                        "qty":1,
                        "amount":30000
                    }
                ],
                "actions":[
                    {
                        "type":"Pay"
                    },
                    {
                        "type":"Print"
                    }
                ]
            }
        ],

    },
    {
        "name":"Sales Order",
        count:1
    } 
]

const pendingTransactionList = {
    "type":"QuickNotificationModuleStyle1",
    "groups":[
        {
            "name":"Sales",
            "count":12,
            "data":[
                {
                    "name":"Sales Invoice",
                    "count":3,
                    "amount":50000
                },
                {
                    "name":"Sales Order",
                    "count":7,
                    "amount":74000
                }
            ]
        },
        {
            "name":"Purchases",
            "count":0,
            "data":[]
        },
        {
            "name":"Cash Management",
            "count":0,
            "data":[]
        },
        {
            "name":"Asset Management",
            "count":7,
            "data":[]
        },
        {
            "name":"Expenses",
            "count":4,
            "data":[]
        },
        {
            "name":"Payroll",
            "count":2,
            "data":[]
        }

    ]
}

let mockdata = {};

// assign values to mock endpoints
mockdata[api.home] = {
    "type": "DesktopLayout1",
    "children":[
        {
            type:"LeftArea1",
            children:[
                {...bundle},
                {...appMenu},
                {...walletBalance2}
            ]
        },
        {
            type:"MainArea1",
            children:[
                {
                    type:"TwoColumnRow",
                    children: [
                        {...walletBalance},
                        {...searchControl1}
                    ]
                },
                {...quickNotification},
                {...graph},
                {...recentTransactions}
            ]
        },
        {
            type:"RightArea1",
            children:[
                {...quickHeaderMenu},
                {...favourite},
                {...addNewFavourite}
            ]
        }
    ]
}
mockdata[api.assetDisposal] = {...formStepper}
mockdata[api.newTransaction] = {
    "type": "ModuleLayout1",
    "children":[
        {
            type:"LeftArea1",
            children:[
                {...bundle},
                {...appMenu},
                {...walletBalance}
            ]
        },
        {...appModule},
        {
            type:"RightArea1",
            children:[
                {...quickHeaderMenu},
                {...favourite},
                {...addNewFavourite}
            ]
        }
    ]
}
mockdata[api.graphData] = graphData;
mockdata[api.walletBalance] = walletBalance;
mockdata[api.sales] = sales;
mockdata[api.pendingTransaction] = {
    "type": "ApprovalLayout1",
    "children":[
        {
            type:"LeftArea1",
            children:[
                {...bundle},
                {...appMenu},
                {...walletBalance}
            ]
        },
        {
            type:"MainApprovalArea1",
            children:[
                {
                    type:"TwoColumnRow",
                    children: [
                        {...walletBalance},
                        {...searchControl1}
                    ]
                },
                {...quickNotification},
                {...pendingTransactionList}
                
            ]
        },
        {
            type:"RightArea1",
            children:[
                {...quickHeaderMenu},
                // {...favourite},
                // {...addNewFavourite}
            ]
        }
    ]
}

mockdata[api.setup] = mockdata[api.open] = mockdata[api.report] = {
    "type": "DashboardLayout1",
    "children":[
        {
            type:"LeftArea1",
            children:[
                {...bundle},
                {...appMenu},
                {...walletBalance}
                // {...setupMenu}
            ]
        },
        {
            type:"SectionGrid",
            children:[
            ]
        },
        {
            type:"RightArea1",
            children:[
            ]
        }
    ]
}

mockdata[api.approvals]  = {
    "type": "ApprovalLayout1",
    "children":[
        {
            type:"LeftArea1",
            children:[
                {...bundle},
                {...appMenu},
                {...walletBalance}
            ]
        },
        {
            type:"SectionGrid",
            children:[
                {...pendingTransactionList}
                
            ]
        },
        {
            type:"RightArea1",
            children:[
                {...quickHeaderMenu},
                // {...favourite},
                // {...addNewFavourite}
            ]
        }
    ]
}

// fetch data from database
// console.log("dashboardData",dashboardData);
mockdata[api.home] = dashboardData
mockdata[api.setup] = setupData
// mockdata[api.assetDisposal] 
mockdata[api.newTransaction] = transactionData
mockdata[api.open] = openData
mockdata[api.memo] = memoData
// mockdata[api.graphData] 
// mockdata[api.walletBalance] 
// mockdata[api.sales] 
// mockdata[api.pendingTransaction] 
// mockdata[api.setup] = mockdata[api.open] = mockdata[api.report] 
mockdata[api.approvals] = approvalsData

// window.fetch = (url, options = {}, timeout=0) => 
// new Promise((resolve,reject) => setTimeout(() => 
//     resolve({
//         json: () => Promise.resolve(mockdata[url]),
//       })
// ,timeout))
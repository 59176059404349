import * as React from "react";

const ConfirmationIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="2 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke-width="3"
    // class="w-6 h-6"
    
    {...props}
  >

    <path 
    stroke-linecap="round" 
    stroke-linejoin="round" 
    fill="none"
    d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" 
    />
  </svg>
);

export default ConfirmationIcon;



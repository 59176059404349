import { isValidUrl } from "../util";
import FileThumbnail from "./FileThumbnail";


const TransformValue = ({value}) => {

    try{
        value = JSON.parse(value)
    }catch{}

    if(Array.isArray(value))
    return (
        <>
             {value?.map(ref => {
                 return (
                    isValidUrl(ref) ? <FileThumbnail name={ref} /> : <span>{ref}</span>
                 )
             })}
        </>
    )

    if(({}).constructor === value?.constructor){
        return (
            <span dangerouslySetInnerHTML={{__html: value?.name}}></span>
        )
    }

    return (
        <span dangerouslySetInnerHTML={{__html: value}}></span>
    )
}

export default TransformValue
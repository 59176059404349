import React, { useState } from 'react';
import formatter from "../util/formatter";
import uuid from 'react-uuid'
import {Accordion, AccordionHead, AccordionBody, AccordionItem} from './Accordion';
import styled from 'styled-components';
import {ScreenLoader, ScreenOverlay} from "./ScreenLoader"
import { Transition } from 'react-transition-group';
import "../assets/styles/sidebar-modal.css"
import AddFavouriteItem from './AddFavouriteItem';
import { XIcon } from '@heroicons/react/solid';
import SidebarModal from './SidebarModal';
import WithLoader from './WithLoader';
import {ViewInactiveIcon } from '../LineIcons';
import { Dialog } from '@headlessui/react'
import appFetch from '../appFetch';
import apiEndpoints from '../apiEndpoints';
import renderComponent from '../renderComponent';
import DrillModalDialog from './DrillModalDialog';
import { appendQueryToUri } from '../util/generalUtil';
import { FavouriteReport } from '../constants/variables';


// const CloseModal = styled.a`
//   position:absolute;
//   right:10px;
// `

// const checkoutTransition = {
//   entered:  { opacity: 1 ,transform:"translateX(0)"}
// };

const FavouriteItemCard = styled.div`
background: ${({theme}) => theme.colors.accent};
color: ${({theme}) => theme.colors.primary};
`



const AddFavouriteItemButton = styled.button`
  background: ${({theme}) => theme?.colors?.primary}
`
  
// const ModalDialog = ({isOpen,item,closeModal}) => {
//   return (
//       <Dialog  as="div"
//       className="fixed inset-0 z-10 overflow-y-auto"
//       onClose={closeModal} 
//       open={isOpen}>
//     <div className="min-h-screen h-full px-4 text-center">
//     <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
//         <span
//           className="inline-block h-screen align-middle"
//           aria-hidden="true"
//         >
//           &#8203;
//         </span>
//       <div className="inline-block w-full max-w-6xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
//         {item && renderComponent(item)}
//         <div className="mt-4 flex justify-end">
//           <button
//             type="button"
//             className="pointer inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-primary border border-transparent rounded-md"
//             onClick={closeModal}>
//             Close
//           </button>
//         </div>
//       </div>
//     </div>
//   </Dialog>
//   )
// }

function FavouriteList1({options,name,children,setLoading,setSidebarState}) {
    const [inProp,setInProp] = useState(false) 
    const [isOpen, setIsOpen] = useState(false)
    const [modalData,setModalData] = useState(null)

    console.log("new options", options);

    const openFavouriteSidebar = () => {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
        setInProp(true)
        // setInProp(true);
      }, 1000)
    }

    const showDetail = (item) => {
      console.log("item",item)

      setLoading(true)
      let postData = {    
          "layoutId": item.layoutid,
          "targetId": item.targetid,
          "target": item.target,
          "drillId":item.drillid?.toString(),
          "targetName":item?.targetname,
          "targetType":item?.targettype,

        }

      appFetch.get(appendQueryToUri(apiEndpoints.menuView,postData)).then(res => {
          console.log("modal res", res)
          const {layoutid,targetid,drillid} = item
          setModalData({...res,layoutid,targetid,drillid,target:FavouriteReport})
          setIsOpen(true)
      })
      .catch(e => console.log(e.message))
      .finally(() => setLoading(false));
  }

    return (
      <>
        {isOpen && <DrillModalDialog isOpen={isOpen} closeModal={() => setIsOpen(false)} item={modalData} />} 
        <SidebarModal inProp={inProp} closeSidebar={() => setInProp(false)}>
            <AddFavouriteItem options={options} />
        </SidebarModal>
        <div className='my-10'>
          <h1 className="font-bold">{name}</h1>
          <Accordion>
            {options?.map((item, idx) => {
              let {name, data, options} = item
              return (
              <AccordionItem key={idx}>
                <AccordionHead>{name} 
                {/* {options?.length > 0 &&
                  <button className='absolute right-12 inline-flex items-center p-1 px-2 rounded-md bg-background-yellow shadow hover:text-main-black' 
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    showDetail(item)
                  }}>
                  <ViewInactiveIcon className="w-5 h-5 mr-0.5"
                    aria-hidden="true"/>
                    <span className="text-xs font-medium">View</span> 
                  </button>
                } */}
                  </AccordionHead>
                  <AccordionBody>
                    <ul className="pl-6">
                              {options?.map((item,idx) => (
                              <li key={idx} className="flex content-center mb-6">
                                    <FavouriteItemCard className="initial drop-shadow font-semibold text-md pb-2 pt-2 w-14 inline-flex justify-center px-1 rounded mr-4 self-start">
                                      {item.ref}
                                    </FavouriteItemCard>
                                    <div className="flex-1 grid leading-normal">
                                        <h1 className="font-semibold hover:underline cursor-pointer text-md" onClick={() => showDetail(item)}>{item.name}</h1>
                                        <h3 className="font-medium text-sm" dangerouslySetInnerHTML={{ __html: item.code }} />
                                        <h5 className="font-medium text-xs" dangerouslySetInnerHTML={{ __html: item.value }} />
                                    </div>
                              </li>
                          ))}
                      </ul>
                  </AccordionBody>
              </AccordionItem>
            )})}
          </Accordion>
        </div>
        
        {options?.length > 0 &&
        <>
          <AddFavouriteItemButton className='uppercase text-white py-5 auto font-semibold rounded w-full' onClick={openFavouriteSidebar}>Filter</AddFavouriteItemButton>
          <div className="mt-5">
            <p style={{color:"#9A9FAD"}} className='text-sm'>Add favourite report you want to be visible on the dashboard</p>
          </div>
        </>
        }
      </>


    );
}

export default WithLoader(FavouriteList1)
// export default FavouriteList1;
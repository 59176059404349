import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ScreenLoader } from './ScreenLoader';

export const WithLoader = WrappedComponent => {
    function HOC(props){
        const [isLoading,setLoading] = useState(false)
        const setLoadingState = (loadingState) => {
            setLoading(loadingState)
        }
        
        return (
            <>
                {isLoading && <ScreenLoader />}
                <WrappedComponent {...props} setLoading={setLoadingState}  />
            </>
        );
    }

    return HOC;
}


export default WithLoader;
import React from 'react';

function SectionGrid({children}) {
    return (
        <div className="grid">
            {children}
        </div>
    );
}

export default SectionGrid;
import React from 'react';

import ApplicationList1 from './ApplicationList1';
import ButtonStyle1 from './ButtonStyle1';
import MenuList1 from './MenuList1';
import MenuList2 from './MenuList2';
import SearchControl1 from './SearchControl1';
import FavouriteList1 from './FavouriteList1'
import LeftArea1 from './LeftArea1';
import RightArea1 from './RightArea1';
import QuickNotificationList1 from './QuickNotificationList1';
import ChartControl1 from './ChartControl1';
import RecentTransactionList1 from './RecentTransactionList1';
import SectionGrid from './SectionGrid';
import MainModuleArea1 from './MainModuleArea1';
import ModuleList1 from './ModuleList1';
import FormStepper1 from './FormStepper1';
import Form1 from "./Form1"
import WalletBalanceStyle1 from './WalletBalanceStyle1';
import WalletBalanceStyle2 from './WalletBalanceStyle2';
import TwoColumnRow from './TwoColumnRow';
import TopNavActionMenu1 from './TopNavActionMenu1';
import TopNavActionButton from './TopNavActionButton';
import PendingApprovalList1 from './PendingApprovalList1';
import MainArea1 from './MainArea1'
import MainApprovalArea1 from './MainApprovalArea1'
import ChartControl2 from './ChartControl2';
import ChartFilterControl1 from './ChartFilterControl1';
import TopNavMenuList1 from './TopNavMenuList1';
import ChartWrapperControl1 from './ChartWrapperControl1'
import RecordWithFormWrapper from './RecordWithFormWrapper';
import GridAndFormWrapper1 from './GridAndFormWrapper1';
import SavedRecordList1 from './SavedRecordList1';
import GridAndFormStepperWrapper1 from './GridAndFormStepperWrapper1';
import ApprovalTransactionList1 from './ApprovalTransactionList1';
import MainMemoArea1 from './MainMemoArea1';
import MainOpenArea1 from './MainOpenArea1';
import SavedTransactionList1 from './SavedTransactionList1';
import ModalLayout1 from './ModalLayout1';
import DateNotificationList1 from './DateNotificationList1';
import ChartControl3 from './ChartControl3';
import ChartControl4 from './ChartControl4';
import BannerArea1 from './BannerArea1';
import MiddleArea1_3 from './MiddleArea1_3';

export default {
    ApplicationList1,
    ButtonStyle1,
    MenuList1,
    MenuList2,
    SearchControl1,
    FavouriteList1,
    LeftArea1,
    RightArea1,
    QuickNotificationList1,
    ChartControl1,
    RecentTransactionList1,
    SectionGrid,
    MainModuleArea1,
    FormStepper1,
    Form1,
    WalletBalanceStyle1,
    WalletBalanceStyle2,
    TwoColumnRow,
    TopNavActionButton,
    TopNavMenuList1,
    PendingApprovalList1,
    MainArea1,
    MainApprovalArea1,
    ChartControl2,
    ChartFilterControl1,
    ChartWrapperControl1,
    ModuleList1,
    RecordWithFormWrapper,
    GridAndFormWrapper1,
    SavedRecordList1,
    GridAndFormStepperWrapper1,
    SavedTransactionList1,
    ApprovalTransactionList1,
    MemoTransactionList1:ApprovalTransactionList1,
    OpenTransactionList1:ApprovalTransactionList1,
    MainMemoArea1,
    PendingMemoList1:PendingApprovalList1,
    PendingOpenList1:PendingApprovalList1,
    MainOpenArea1,
    ModalLayout1,
    DateNotificationList1,
    ChartControl3,
    ChartControl4,
    BannerArea1,
    MiddleArea1_3,
    MiddleArea2_3:MiddleArea1_3,
    MiddleArea3_3:MiddleArea1_3,
    QuickNotificationList2: QuickNotificationList1,
    ChartWrapperControlm11: ChartWrapperControl1,
    ChartWrapperControlm12:ChartWrapperControl1,
    ChartWrapperControlm21: ChartWrapperControl1,
    ChartWrapperControlm22:ChartWrapperControl1,
    ChartWrapperControl31:ChartWrapperControl1,
    ChartWrapperControlm31:ChartWrapperControl1,
    ApplicationList2:ApplicationList1
}



import React from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput from "react-phone-number-input"
import {ErrorMessage,useField } from "formik";


const CustomInput = () => {
    return (
        <input className="w-full h-full outline-none focus:outline-none"/>
    )
}

function PhoneNumber(props) {
    const { name, label, validation } = props
    const [field, meta, { setValue, setTouched }] = useField(name);

    return (
        <>
        {label && <label className="text-sm font-normal inline-block text-custom-gray-400" htmlFor={name}>{label} {validation?.required == true && <span className="text-sm text-asterisk-red">&#42;</span>}</label>}
        <PhoneInput
            defaultCountry="NG"
            value={field.value}
            onChange={setValue}
            international={false}
            addInternationalOption={false}
            countries={["NG", "US", "CA", "GH", "GB"]}
            style={{outline:"none"}}
            focusInputOnCountrySelection={false}
            className="flex pl-3 placeholder-custom-gray-100 placeholder-opacity-40 h-11 appearance-none relative block w-full px-3 py-2 border border-custom-gray-300 text-gray-500 rounded-md focus:outline-none sm:text-sm"
            />
        <ErrorMessage name={name} render={msg => <div className="text-xs text-red-600 mt-1">{msg}</div>} />
        </>
    );
}

export default PhoneNumber;
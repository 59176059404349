import { getRootPath } from "./util/generalUtil";
import { getFromLS } from "./util/storage";


const fetchReq = (url,options) => fetch(url,options)
.then(async response => {
    let data = null;
    let clone = await response.clone()
    try{
        data = await response.json();
    }catch{
        console.log("response",response)
        data = await clone.text();
    }
    

    //check for error response
    if (!response.ok) { 
        if(response.status === 403){
            localStorage.clear(); 
        }
         if(response.status === 401){
            // const loginUrl = getFromLS("loginUrl");
            localStorage.clear(); 
            let rootPath = getRootPath();
            window.location = rootPath ? `/${rootPath}/login` : 'login'
        }
        let error = {message : data.message ?? response.statusText };
        return Promise.reject(error);
    }
    return Promise.resolve(data);
})
.catch(error => {
    console.log("error",error);
    return Promise.reject(error);
});

const setOptions = (method,options) => {

    let token = localStorage.getItem("token") ?? ""

    let defaultOptions = {
        method:  method,
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${token}`
        }
    };

    console.log("options fetch", Object.assign({}, options))
    // options.headers["Authorization"] = `Bearer ${token}`

    options = {...defaultOptions, ...options};
    console.log("fetchOptions",options);
    return options
}

export default {
    get : url => Promise.resolve(fetchReq(url,setOptions("GET"))),
    post : (url,options) => Promise.resolve(fetchReq(url,setOptions("POST",options))),
    put : (url,options) => Promise.resolve(fetchReq(url,setOptions("PUT",options))),
}


import React from 'react';

import CategoryIcon from "./CategoryIcon"
import ChevronDownIcon from "./ChevronDownIcon"
import CircleIcon from "./CircleIcon"
import DownloadIcon from "./DownloadIcon"
import FilterIcon from "./FilterIcon"
import HomeIcon from "./HomeIcon"
import NotificationIcon from "./NotificationIcon"
import PaymentIcon from "./PaymentIcon"
import PlusIcon from "./PlusIcon"
import SearchIcon from "./SearchIcon"
import SettingIcon from "./SettingIcon"
import UserIcon from "./UserIcon"
import UsersIcon from "./UsersIcon"
import GroupIcon from "./GroupIcon"
import PlusRound from './PlusRound';
import AccountingLogo from './AccountingLogo';
import MaleAvatar from './MaleAvatar';
import ReceiveIcon from './ReceiveIcon';
import InvoiceIcon from './InvoiceIcon';
import PayIcon from './PayIcon';
import PrintIcon from './PrintIcon';
import ArrowDown from './ArrowDown';
import CategoryFillIcon from './CategoryFillIcon';
import SetupIcon from './SetupIcon';
import TransactionIcon from './TransactionIcon';
import ApprovalIcon from './ApprovalIcon';
import MemoIcon from './MemoIcon';
import OpenIcon from './OpenIcon';
import RecentIcon from './RecentIcon';
import DashboardIcon from './DashboardIcon';
import ConfirmationIcon from './ConfirmationIcon';
import PayrollReportsIcon from './PayrollReportsIcon';
import OtherReportsIcon from './OtherReportsIcon';
import EssReportsIcon from './EssReportsIcon';
import BioDataIcon from './BioDataIcon';

export default {
    CategoryIcon,
    ChevronDownIcon,
    CircleIcon,
    DownloadIcon,
    FilterIcon,
    HomeIcon,
    SetupIcon,
    TransactionIcon,
    ApprovalIcon,
    MemoIcon,
    OpenIcon,
    NotificationIcon,
    PaymentIcon,
    PlusIcon,
    SearchIcon,
    SettingIcon,
    UserIcon,
    UsersIcon,
    GroupIcon,
    PlusRound,
    AccountingLogo,
    MaleAvatar,
    ReceiveIcon,
    InvoiceIcon,
    PayIcon,
    PrintIcon,
    ArrowDown,
    CategoryFillIcon,
    InvoiceIcon,
    FileIcon:RecentIcon,
    DashboardIcon,
    ConfirmationIcon,
    PayrollReportsIcon,
    OtherReportsIcon,
    EssReportsIcon,
    BioDataIcon
};
import React from 'react';

function TwoColumnRow(props) {
    return (
        <div className="flex justify-between align-center">
        {props.children}
    </div>
    );
}

export default TwoColumnRow;
import React, { useContext, useState } from 'react';
import AppContext from '../contexts/AppContext';


function ChartWrapperControl1({children}) {

    let chartFilterOptions = children[0]?.props?.children?.props?.options
    let chartControlOptions = children[1]?.props?.children?.props?.options

    // let [{
    //     props: {
    //         children: {
    //             props: {
    //                 options: chartFilterOptions
    //             }
    //         }
    //     }
    // }, {
    //     props: {
    //         children: {
    //             props: {
    //                 options: chartControlOptions
    //             }
    //         }
    //     }
    // }] = children;

    const {context,setContext} = useContext(AppContext);

    const initialState = {chartFilterOptions,chartControlOptions};
    const [triggered,setTriggered] = useState(false)

    // const updateAppContext = () => {
    //     let newOptions = [...chartFilterOptions];
    //     newOptions[0].options.push({
    //         "chartname": "Verizone Chart",
    //         "charticon": "Verizone Chart",
    //         "target": "component",
    //         "layoutid": 451,
    //         "targetid": 2001
    //     });
    //     const newContext = {...context, chartFilterOptions:newOptions,chartControlOptions}
    //     setContext(newContext)
    // }

    return (
        <div>
            {/* <button 
            className='border rounded uppercase font-semibold p-3' 
            onClick={updateAppContext}>
                {triggered ? "Triggered" : "Not Triggered"}
            </button> */}
            
            {children}
        </div>
    );
}

export default ChartWrapperControl1;
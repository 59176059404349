import React, { useContext, useEffect, useState } from "react";
import { appendQueryToUri, groupBy } from "../util/generalUtil";
import TransformValue from "./TransformValue";
import classNames from "classnames";
import WithLoader from "./WithLoader";
import Select from "react-select";
import styled from "styled-components";
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon";
import Spinner from "../loaders/Spinner";
import appFetch from "../appFetch";
import apiEndpoints from "../apiEndpoints";
import AppContext from "../contexts/AppContext";
import DataTable1 from "./DataTable1";
import moment from "moment";

window._moment = moment;

const EmptyRecord = () => {
  return (
    <div className="grid place-content-center place-items-center h-60 bg-gray-100 rounded-sm mb-5">
      <EmptyTransactionIcon />
      <h2 className="font-semibold text-md">No record available</h2>
      <p className="text-sm text-gray-700">Check back in a bit!</p>
    </div>
  );
};

const ReportOption = ({ innerProps, data, children, label }) => {
  return (
    <div className="custom-select-option" {...innerProps}>
      {children}
    </div>
  );
};

const ReportOptionValue = ({ children, data }) => {
  return <div className="custom-select-option-value">{children}</div>;
};

const FilterItem = styled.div`
  background: ${({ theme }) => theme.colors.primary};
`;

const TagFilterItem = ({ active, period, onClick }) => (
  <FilterItem
    className={classNames("tag filter", { active: active })}
    onClick={onClick}
  >
    {period}
  </FilterItem>
);

function DateNotificationList1({ setLoading, name, options, children }) {
  const {
    context: { menuId },
  } = useContext(AppContext);
  console.log(options);
  console.log(children);

  const reportOptions =
    options?.map(({ period, grid, ...rest }) => ({
      ...rest,
      label: rest.name,
    })) || [];
  /* const form = children[0]?.props?.children?.props; */

  const [loadingPeriodData, setLoadingPeriodData] = useState(false);

  //set state in effect
  const [activeReport, setActiveReport] = useState(null);

  // const data = options?.find(o => activeReport?.name === o.name)
  // const periodTypeGroup = groupBy(data?.period || [], "period_type");
  // const periodTypes = Object.keys(periodTypeGroup);

  const [periodTypeGroup, setPeriodTypeGroup] = useState(null);
  const [periodTypes, setPeriodTypes] = useState(null);

  console.log("reportOptions === ", {
    activeReport,
    periodTypeGroup,
    periodTypes,
  });

  // const [activePeriod, setActivePeriod]  = useState(periodTypeGroup[activePeriodType]?.slice(-1)?.at(0))
  // const [activePeriodType,setActivePeriodType] = useState(periodTypes[0])
  // const [dataGrid,setDataGrid] = useState(data?.grid?.filter(o => o.ID == activePeriod?.ID));

  const [activePeriod, setActivePeriod] = useState(null);
  const [activePeriodType, setActivePeriodType] = useState(null);
  const [dataGrid, setDataGrid] = useState(null);
  /* const {excel_template, name:templateName, target_id} = form; */

  useEffect(() => {
    if (menuId) {
      let activeReport = reportOptions[0] ?? null;

      let data = options?.find((o) => activeReport.name === o.name);
      let periodTypeGroup = groupBy(data?.period || [], "period_type");
      let periodTypes = Object.keys(periodTypeGroup);

      let activePeriodType = periodTypes[0];
      let activePeriod = periodTypeGroup[activePeriodType]?.slice(-1)?.at(0);

      setPeriodTypeGroup(periodTypeGroup);
      setPeriodTypes(periodTypes);

      setActiveReport(activeReport);
      setActivePeriodType(activePeriodType);
      setActivePeriod(activePeriod);
      setDataGrid(data?.grid?.filter((o) => o.ID == activePeriod?.ID));

      console.log("reportOptions", {
        activeReport,
        periodTypeGroup,
        periodTypes,
        activePeriod,
        dataGrid,
        data,
        options,
      });
    }
  }, [menuId]);

  // console.log({periodTypeGroup,periodTypes,activePeriod,dataGrid,data})

  let gridKeys = Object.keys((dataGrid ?? [])[0] || {})?.filter(
    (head) => !["ID", "drillable", "drillid"].includes(head)
  );

  const fetchActivePeriodData = (period, reportType) => {
    setLoadingPeriodData(true);
    let postData = {
      layoutId: reportType?.layoutid,
      schemaId: period?.ID,
      target: reportType?.target,
      targetId: reportType?.targetid,
    };

    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        let grid = (res?.options || [])[0]?.grid || [];
        setDataGrid(grid);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setLoadingPeriodData(false));
  };

  const exportData = async () => {
    console.log({activeReport ,activePeriod})
    setLoading(true);
    let postData = {
      urlMenu: null,
      layoutId: activeReport?.layoutid,
      targetId: activeReport?.targetid,
      target: activeReport?.target,
      schemaId: activePeriod?.ID,
      txnGroup: null,
      parentDropValue: null,
      drillId: null,
      targetName: null,
      targetType: null,
      outputType: "DATASET",
    };

    let token = localStorage.getItem("token") ?? "";
    fetch(appendQueryToUri(apiEndpoints.downloadData, postData), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${activeReport.name?.replace(/\s/g,'-')}-${moment().format(
          "YYYY-MM-DD_h:msa"
        )}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectUrl(url);
      })
      .catch((e) => console.log("error message", e.message))
      .finally((e) => setLoading(false));
  };

  const changeActivePeriod = (item) => {
    setActivePeriod(item);
    fetchActivePeriodData(item, activeReport);
  };

  const changePeriodType = (periodType) => {
    setActivePeriodType(periodType);
    let activePeriod = periodTypeGroup[periodType]?.slice(-1)?.at(0);
    if (activePeriod) {
      changeActivePeriod(activePeriod);
    }
  };

  const onReportChange = (selectedOption) => {
    setActiveReport(selectedOption);
    fetchActivePeriodData(activePeriod, selectedOption);
  };

  return (
    <div
      className="mb-6 shadow-md sm:rounded-lg mt-4 p-6"
      style={{
        boxShadow:
          "0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -2px rgb(0 0 0 / 10%), 0 -1px 6px -1px rgb(0 0 0 / 10%)",
      }}
    >
      {/* <div className='mb-6 shadow-md sm:rounded-lg mt-4 p-6' style={{boxShadow:"0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -2px rgb(0 0 0 / 10%), 0 -1px 6px -1px rgb(0 0 0 / 10%), 0 -2px 4px -2px rgb(0 0 0 / 10%)"}}></div> */}
      {options === null && <EmptyRecord />}
      {options && (
        <>
          <div className="border-bottom flex flex-col md:flex-row items-center rounded-md py-2 justify-between">
            {/* <h1 className='font-bold'>xf
              {name}
          </h1>   */}

            {/* <div className='filter-group flex items-center flex-1 justify-end'> */}
            <div className="tags flex-1 mb-3 sm:mb-0">
              {periodTypes?.map((period, index) => {
                let active = period === activePeriodType ? "active" : "";
                return (
                  <TagFilterItem
                    key={index}
                    period={period}
                    active={active}
                    onClick={() => changePeriodType(period)}
                  />
                );
              })}
            </div>
            <div className="mr-5">
              <button
                className="mb-3 sm:mb-0 bg-primary hover:bg-primary text-white tracking-normal font-semibold text-xs py-2 px-4 sm:rounded rounded-full inline-flex items-center"
                onClick={exportData}
              >
                <span className="">Export</span>
              </button>
            </div>
            <Select
              classNamePrefix="custom-select-option"
              components={{
                Option: ReportOption,
                SingleValue: ReportOptionValue,
              }}
              value={activeReport}
              isClearable={false}
              onChange={onReportChange}
              options={reportOptions}
            />
            {/* </div> */}
          </div>

          <div className="">
            <div className="period-list flex flex-wrap justify-between mt-6">
              {periodTypeGroup &&
                periodTypeGroup[activePeriod?.period_type]?.map((item) => {
                  let isActivePeriodItem = item.ID === activePeriod.ID;
                  return (
                    <button
                      onClick={(e) => changeActivePeriod(item)}
                      className={classNames(
                        "flex p-2 flex-col cursor-pointer",
                        {
                          "bg-primary text-white rounded-md shadow":
                            isActivePeriodItem,
                        }
                      )}
                    >
                      <span className="font-normal">{item.period_l1}</span>
                      <span className="font-bold text-sm">
                        {item.period_l2}
                      </span>
                    </button>
                  );
                })}
            </div>
            <div className="mt-6">
              <h2 className="font-medium">{activePeriod?.period_name}</h2>
              <div className="relative overflow-x-auto mt-4">
                <DataTable1
                  loading={loadingPeriodData}
                  setLoading={setLoadingPeriodData}
                  setGrid={(grid) => setDataGrid(grid)}
                  rows={dataGrid || []}
                  activePeriod={activePeriod}
                  headers={gridKeys}
                  headerProps={activeReport}
                />

                {/* <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    {gridKeys?.map((str, idx) => (
                      <th scope="col" className="px-4 py-3 text-xs" key={idx}>
                        {str}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {loadingPeriodData && 
                    <div className="grid place-items-center place-content-center h-20">
                      <span className="">Loading...</span>
                      <Spinner width={32} height={32} fill="#aaaaaa" />
                    </div>
                    }
                  {!loadingPeriodData && <>
                  {dataGrid?.map((line, key) => {
                    return (
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                        {gridKeys?.map((str, idx) => (
                          <td className="px-4 py-4 text-xs">
                            <TransformValue value={line[str]} />
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                  {(dataGrid?.length === 0 || dataGrid == null) && (
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                      <td className="px-4 py-4 text-md">
                        <span className="flex justify-center font-bold">
                          No record available.
                        </span>
                      </td>
                    </tr>
                  )}
                  </>}
                </tbody>
              </table> */}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default WithLoader(DateNotificationList1);

import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import styled from "styled-components"
import AppContext from '../contexts/AppContext';
import Select from "react-select"
import WithLoader from './WithLoader';
import apiEndpoints from '../apiEndpoints';
import appFetch from '../appFetch';
import { appendQueryToUri } from '../util/generalUtil';
import uuid from 'react-uuid';

const ChartOption = ({innerProps, data, children, label}) => {
    return (
        <div className="custom-select-option" {...innerProps}>
            {children}
        </div>
    );
}

const ChartOptionValue = ({children, data}) => {
    return (
        <div className="custom-select-option-value">
            {children}
        </div>
    );
}

// const CountryValue = ({children, data}) => {
//     return (
//         <div className="country-value">
//             <ReactCountryFlag
//                     countryCode={data.CountryCode}
//                     className="country-flag"
//                     svg
//                     style={{
//                         width: '2em',
//                         height: '2em',
//                     }}
//                     title={data.Country}
//                 />
//             {children}
//         </div>
//     );
// }

const FilterItem = styled.div`
    background: ${({theme}) => theme.colors.primary}
`

const TagFilterItem = ({active,chartname,targetid, onClick}) => (<FilterItem className={classNames("tag filter", {"active":active})} onClick={onClick} data-filter={targetid}>{chartname}</FilterItem>)

const VerticalLine = styled.div`
    font-size: 24px;
    color: #b0b0b0;
    `

function ChartFilterControl1({options,name,setLoading}) {

    const [activeIndex,setActiveIndex] = useState(0);
    const {context,setContext} = useContext(AppContext)
    const [defaultVal,setDefaultVal] = useState(null)

    const filterOption = options?.map(({name,id}) => ({
        value:name,
        label:name,
        id:id
    })) || []

    //get options from context
    options = context?.chartFilterOptions ?? options;

    const activeTender = options?.find(({name:itemName}) => itemName == name);

    console.log("default value", defaultVal)

    const handleChange = selectedOption => {
     const item = (options.find(({name}) => selectedOption.label === name)?.options ?? [])[0]
      console.log("selectedOption",item,options)
    
      setLoading(true)
      let postData = {    
          "urlMenu": null,
          "layoutId": item?.layoutid,
          "targetId": item?.targetid,
          "target": item?.target,
          "schemaId": null,
          "txnGroup": null,
          "parentDropValue":null,
          "drillId": null,
          "targetName":null,
          "targetType":null,

        }

      appFetch.get(appendQueryToUri(apiEndpoints.menuView,postData))
      .then(res => {
          console.log("res", res,context)
        
        // if (context.pageConfig.children[1].children[1].children[1].type != res.type){
        // context.pageConfig.children[1].children[1].children[1] = res}
        function replaceChartControl(node, res) {
            if (node.children) {
              node.children = node.children.map((child) => replaceChartControl(child, res));
            }

            // if(context.pageConfig){
            //     context.pageConfig.children.map((child) => {
            //         if(child.type === 'MainArea1'){
            //             child.children.map((child2) => {
            //                 if(child2.type  === 'ChartWrapperControl1'){
            //                     child2.children.map((child3)=>{
            //                         if((child3.type !== 'ChartFilterControl1') && (child3.type !== res.type)){
            //                                 console.log(child3)
            //                                 child3=res
            //                                 console.log(child3);
            //                                 console.log(context.pageConfig.children[1].children[1].children[1]);
            //                         }
            //                     })
            //                 }
            //             })
            //         }
            //     })
            // }
          
            if (node.type === 'ChartWrapperControl1') {
              node.children = node.children.map((child) => {
                if((child.type !== 'ChartFilterControl1') && (child.type !== res.type)){
                // if (child.type === res.type || !('type' in res)){
                  return res;
                // }
                }
                return child;
              });
          
              return node;
            }
          
            return node;
          }
          
          const updatedChildren = context.pageConfig.children.map((child) => {
            if (child.type === 'MainArea1') {
              return replaceChartControl(child, res);
            }
          
            return child;
          });
          
          context.pageConfig.children = updatedChildren;

        // function findAndUpdate(obj, targetType, newValue) {
        //     if (obj.type !== targetType) {
        //       Object.assign(obj, newValue);
        //       return true;
        //     }
        //     if (Array.isArray(obj.children)) {
        //       for (let child of obj.children) {
        //         if (findAndUpdate(child, targetType, newValue)) {
        //           return true;
        //         }
        //       }
        //     }
        //     return false;
        //   }
          
        //   findAndUpdate(context.pageConfig, res.type, res);
          
          
        //   if(context.pageConfig.childr)

              console.log('Context',context);
        // if(context.pageConfig){
        //     if()
        // }
        // const context1 = {
        //     pageConfig: {
        //         type: 'HomeLayout1',
        //         children: [{type: 'LeftArea1', children:[]}, 
        //                     {type: 'MainArea1', children:[{type: 'List1', name:'Quick Notification',options:[], selectedTargetID: null,children:null},
        //                        {type: 'ChartWrapperControl1', name: 'Chart Wrapper', selectedTargetID:null, options:null, children:[{type:'ChartFilterControl1', name:'Select a Report', options:[]},
        //                             {type: 'ChartControl2', name:'Line Graph', options:[]}]} ]}]
        //     },
        //     menuID: 1480,
        //     chartControlOptions:[],
        // }
        // console.log(context1.pageConfig.children[1].children[1].children[1])

        // const { children } = context.pageConfig;

        // if (children) {
        // children.forEach((c, i) => {
        //     if (c.children?.[1]?.children?.[1]?.type !== res.type) {
        //     children[i].children[1].children[1] = { ...children[i].children[1].children[1], ...res };
        //     }
        // });
        // }
        

        const newContext = {...context,chartControlOptions:res?.options}
        setContext(newContext)
        
      })
      .catch(e => console.log(e.message))
      .finally(() => setLoading(false));
    };

    return (
        <>
            <div className='border-bottom flex flex-col md:flex-row items-center rounded-md py-2 justify-between'>
                <h1 className='font-bold'>
                    {name}
                </h1>
                <div className='filter-group flex items-center flex-1 justify-end'>
                    <div className='tags'>
                        {/* <div>Filter by:</div> */}
                        {activeTender?.options?.map((item, index) => { 
                            const active = index == activeIndex ? "active" : "";
                            return (
                            <TagFilterItem key={index}
                            {...item} 
                            active={active} 
                            onClick={() => setActiveIndex(index)} 
                            />
                        )})}
                    </div>                 
                    <div className=''>
                        <Select 
                        classNamePrefix="custom-select-option"
                        components={{ Option: ChartOption, SingleValue: ChartOptionValue }}
                        key={filterOption[0]?.id}
                        defaultValue={filterOption[0]}
                        isClearable={false}
                        onChange={handleChange}
                        options={filterOption} 
                        />
                    </div>
                </div>
            </div>
        </>

    );
}

export default WithLoader(ChartFilterControl1);
import classNames from 'classnames';
import React, {useContext} from 'react';
import { ThemeContext } from 'styled-components';



function ChipItem({name,count,active,setActive,index, groupDrill, handleMultiButtonAction}) {
    const theme = useContext(ThemeContext)

    return (
        <div className='flex lg:flex-row flex-col justify-between lg:pr-0'>
            <div className='flex cursor-pointer gap-4 py-1 rounded-md px-3 items-center flex-shrink-0' data-index={index} onClick={() => setActive(index)} style={{
                    backgroundColor: active ? theme?.colors.primary : "#F4F7FC",
                    color: active ? "#FFFFFF" : "#838383"
                }}>
                <span className=''>
                    {name}
                </span>
                <span className={classNames("text-sm px-2 bg-white text-white-400 rounded", {"shadow":active})} style={{color: "#5D5D68"}}>                     
                    {count}
                </span>
            </div>
        </div>
    );
}

export default ChipItem;
import { useState, useEffect } from "react";
import apiEndpoints from "../apiEndpoints";
import appFetch from "../appFetch";
import NotAllowed from "../routes/NotAllowed";
import { getFromLS, setToLS } from "../util/storage";
import { SubscriberProvider, useSubscriber } from "../hooks/useSubscriber";

const WithThemeWrapper = WrappedComponent => {

    const HOC = props => {
      const {subs} = useSubscriber()
      const localTheme = getFromLS("theme");
      const subscriberUrl = localTheme?.url?.toLowerCase();
      const [invalidSubs,setInvalidSubs] = useState(false);
      
      let savedTheme = subscriberUrl == subs ? localTheme : null;

      //for already logged in applications
      if(savedTheme?.constructor != ({}).constructor){
        savedTheme = null;
      }

      const [theme, setTheme] = useState(savedTheme)
  
      useEffect(() => {
        appFetch.get(`${apiEndpoints.getAppCache}?subs=${subs}`)
            .then(res => {
                setToLS("theme", res)
                setTheme(res);
            }).catch(res => {
              setInvalidSubs(true);
            })
      }, [subs])
  
      return (
        <>
          <>{theme && <WrappedComponent theme={theme} subs={subs} {...props} />}</>
          <>{invalidSubs && <NotAllowed />}</>
        </>
        
      )
    }
  
    return HOC;
  }

  export default WithThemeWrapper;
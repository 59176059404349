import React, {useContext, useEffect, useState} from 'react';
import api from "../endpoints"
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import FilterIcon from '../icons/FilterIcon';
import AppContext from '../contexts/AppContext'
// import EmptyRecord from './EmptyRecord';
import EmptyTransactionIcon from "../icons/EmptyTransactionIcon"

const EmptyRecord  = () => {
   return ( <div className='grid place-content-center place-items-center h-80 bg-gray-100 rounded-sm mb-5'>
        <EmptyTransactionIcon  />
        <h2 className='font-semibold text-md'>No chart available</h2>
    </div>)
}

function ChartControl3({name,options}) {
    const {context} = useContext(AppContext)
    options = context?.chartControlOptions ?? options;

    const graphData = options[0];    

    const graphOptions = {
                chart: {
                    plotShadow: false,
                    style: {
                        'float': 'left',
                    },
                    marginRight: 280,
                    type: 'pie',
                        },
                    title:{
                        // text: 'Project Earnings in April',
                        // align: 'left',
                        // marginRight: 60,
                        x: -130,
                        style:{
                            // fontFamily: 'sans-serif',
                            fontSize: '22px',
                        }
                    },
                    legend: {
                        align: 'right',
                        margin: 0,
                        x: -180,
                        y:-40,
                        floating: false,
                        layout: 'vertical',
                        verticalAlign: 'middle',
                        itemStyle: {
                            fontSize: '16px',
                        },
                            labelFormatter: function () {
                                let name = this.name.length > 20 ? this.name.substr(0, 20) + '...' : this.name;
                                console.log(this)
                                return `${name}   ${this.y}`
                            }
                      },
                    plotOptions: {
                        pie: {
                            borderColor: null,
                            size: '75%',
                            // borderWidth: 12,
                            slicedOffset: 20,
                            innerSize: '80%',
                            dataLabels: {
                                enabled: false
                            },
                            showInLegend: true
                            }
                    },
                    ...graphData,
    }

    // const graphOptions = options2?.options;
    // const graphData = graphOptions[0];

    

    return (
        <div className='ma mr-50 pb-50'>
                <div id="graph" className="mb-10">
                
                <div className='flex justify-center items-center flex-row'>
                {/* <h1 className='font-bold pr-4 text-2xl'>$69,700</h1>
                <div className='font-semibold bg-red-200 text-red-700 rounded-lg pr-2 pl-2 pt-2 pb-2 text-lg'>{'\u2193'} 2.2% </div> */}
                </div>
                <div>
                    {
                    <HighchartsReact
                    highcharts={Highcharts}
                    options={{...graphOptions, ...graphData}} 
                    containerProps={{ style: { width: "100%" } }}
                    />
                    
                    }
                    </div>
                </div>
        </div>
    );
}

export default ChartControl3;   
import React from "react";
import uuid from "react-uuid";
import component from "./components"

function renderComponent(config,additionalProps) {
  if (typeof component[config.type] !== "undefined") {
    let {type, children, ...otherProps} = config;
    return React.createElement(
        component[type],
        {type,...otherProps,...additionalProps},
        children &&
          (typeof children === "string"
            ? children
            : children.map((c, idx) => <React.Fragment key={idx}>{renderComponent(c)}</React.Fragment>))
      );
  }
}

export default renderComponent

import React, { useEffect, useState } from 'react';
import Spinner from '../loaders/Spinner';
import OtpTimeout from './OtpTimeout';
import toast from 'react-hot-toast';

function OtpControl({closeModal, otpPhone, sendOtp, otherProps,actions}) {

    const [resend,setResendOtp] = useState(false);
    const [sending,setSending] = useState(false);
    const [processing,setProcessing] =  useState(false);


    const postData = (values) => new Promise((resolve, reject) => {
        setProcessing(true)
        // setTimeout(() => resolve({}), 2000);
        // setTimeout(() => setProcessing(false), 2300)
       
        // appFetch.post(apiEndpoints.login, {
        //     body : JSON.stringify({...values, userName}) 
        // }).then(res => {
        //     console.log('value of response is', res)
        //     resolve(res);
        // }).catch(err => reject(err))
        // .finally(() => setProcessing(false)) 
      })


    const handleSubmit = async () =>  {

        if(processing) return;

        let codes = Array.from(document.querySelectorAll("#otp input")).map(m => m.value).join("");
        if(codes.length < 6){
            return;
        }
        

        
        setProcessing(true)
        let response = await actions({...otherProps, codes});
        console.log("response from otp control", response);
        setProcessing(false)
        if(response){
            setTimeout(closeModal, 400)
        }

        // toast.promise(postData({}), {
        //     loading: 'Processing',
        //     success: (res) =>  'Login Successful. Redirecting..',
        //     error: (err) => "Login failed! try again." ,
        // });
    }

    useEffect(() => {
        const inputs = document.querySelectorAll('#otp > *[id]');
        const handleKeyup = (i) => (event) => {
            if (event.key === "Backspace") {
                inputs[i].value = '';
                if (i !== 0) inputs[i - 1].focus();
            } else {
                if (i === inputs.length - 1 && inputs[i].value !== '') {
                    return true;
                } else if (event.keyCode > 47 && event.keyCode < 58) {
                    inputs[i].value = event.key;
                    if (i !== inputs.length - 1) inputs[i + 1].focus();
                    event.preventDefault();
                } else if (event.keyCode > 64 && event.keyCode < 91) {
                    inputs[i].value = String.fromCharCode(event.keyCode);
                    if (i !== inputs.length - 1) inputs[i + 1].focus();
                    event.preventDefault();
                }
            }
        }

        for (let i = 0; i < inputs.length; i++) {
            inputs[i].addEventListener('keydown', handleKeyup(i));
        }

        return () => {
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].removeEventListener('keydown', handleKeyup(i));
            }
        }
    }, [])

    return (
        <>
            <div class="flex justify-center items-center">
                <div class="relative" id="otp">
                    <div class="card p-2 text-center">
                        <h6 className='text-primary leading-none'>Please enter otp sent to your phone <br/> to complete this request</h6>
                        {!sending && <div>
                            <div className='text-sm mt-2'>
                                <span>A code has been sent to <small>{otpPhone}</small></span>
                            </div>
                        </div>}
                        {sending && <div className='mt-2 inline-grid place-items-center'><Spinner width={40} height={40} fill="#333333"  /></div>}

                        <div id="otp" class="inputs flex flex-row justify-center mt-2">
                            <input class="m-2 w-10 h-10 border border-gray-400 text-center form-control rounded" type="text" id="first" maxlength="1" />
                            <input class="m-2 w-10 h-10 border border-gray-400 text-center form-control rounded" type="text" id="second" maxlength="1" />
                            <input class="m-2 w-10 h-10 border border-gray-400 text-center form-control rounded" type="text" id="third" maxlength="1" />
                            <input class="m-2 w-10 h-10 border border-gray-400 text-center form-control rounded" type="text" id="fourth" maxlength="1" />
                            <input class="m-2 w-10 h-10 border border-gray-400 text-center form-control rounded" type="text" id="fifth" maxlength="1" />
                            <input class="m-2 w-10 h-10 border border-gray-400 text-center form-control rounded" type="text" id="sixth" maxlength="1" />
                        </div>
                        <div className='mt-2 text-xs'>
                            Didn't receive an otp? <button className='text-underline text-primary' 
                            onClick={async () => {
                                if(!resend || sending) return;
                                setSending(true);
                                setResendOtp(false)
                                await sendOtp(otherProps)
                                setTimeout(() => setSending(false), 1000)
                            }}>{sending ? "resending..." : "resend"}&nbsp;</button> 
                            {!resend && !sending && <>in <OtpTimeout resend={resend} 
                            onCompleted={() => {
                                setResendOtp(true);
                            }}/> </>}
                            
                        </div>
                        {!sending && 
                        <div class="mt-4">
                            <button
                            type="button"
                            disabled={processing}
                            className="inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2"
                            onClick={handleSubmit}
                            >
                                {processing ? "Verifying..." : "Verify"}
                            </button>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default OtpControl;
import React from "react";

function MainArea1({ children }) {
  return (
    // <div className="px-12 pt-0 flex-1 overflow-auto">
    <div className="lg:px-8 px-4 pb-8 pt-14 lg:pt-8 w-rb overflow-auto">
      <div>{children}</div>
    </div>
  );
}

export default MainArea1;

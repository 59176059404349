import React, { useEffect, useRef, useState } from "react";
import formatter from "../util/formatter";
import { Transition as _Transition } from "react-transition-group";
import ChevronDownIcon from "../icons/ChevronDownIcon";
import IconArrowUp from "../icons/IconArrowUp";
import styled from "styled-components";

import "../assets/styles/accordion/main.scss";

export const AccordionItem = ({
  showDescription,
  ariaExpanded,
  activeRef,
  fontWeightBold,
  key,
  inProp,
  itemHeight,
  index,
  onClick,
  children,
}) => {
  console.log("chldren is", children);

  children = [].concat(children);

  if (children.length < 2 || children.length > 2)
    throw new Error(
      "AccordionItem must have two childs of AccordionHead and AccordionBody"
    );
  //if(children[0].type.name != "AccordionHead") throw new Error('first child must be an AccordionHead');
  //if(children[1].type.name != "AccordionBody") throw new Error('second child must be an AccordionBody');

  // console.log("item options", item.options)

  return (
    <div className="accordion__head relative" key={key} data-item-index={index}>
      <dt>
        <button
          aria-expanded={ariaExpanded}
          aria-controls={`accordion${index + 1}_body`}
          data-qa="accordion__head-button"
          className={`accordion__head-button ${fontWeightBold}`}
          onClick={onClick}
        >
          {/* {item.name} */}
          {children[0]}
        </button>
      </dt>
      <dd>
        <Transition
          {...{
            activeRef,
            showDescription,
            index,
            itemHeight,
            inProp,
            children: children[1],
          }}
        />
      </dd>
    </div>
  );
};

export const AccordionHead = ({ children, className }) => {
  return <>{children}</>;
};

export const AccordionBody = ({ children }) => {
  return <>{children}</>;
};

const FavouriteItemCard = styled.div`
  background: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.primary};
`;

const Transition = ({
  activeRef,
  showDescription,
  index,
  itemHeight,
  inProp,
  children,
}) => {
  const nodeRef = useRef(null);

  const checkoutTransition = {
    entering: {
      height: "0px",
    },
    entered: {
      height: `${itemHeight}px`,
    },
  };

  const duration = 0;

  return (
    <_Transition nodeRef={nodeRef} timeout={duration} in={inProp}>
      {(state) => {
        return (
          <>
            <div
              ref={activeRef}
              id={`accordion${index + 1}_body`}
              className="accordion__body"
              style={{
                ...checkoutTransition[state],
              }}
            >
              {children}
              {/* <ul className="pl-6">
                                    {item.options?.map(({name,value},idx) => (
                                    <li key={idx} className="flex content-center mb-6">
                                          <FavouriteItemCard className="initial font-medium text-3xl pb-2 pt-2 uppercase w-14 inline-flex justify-center rounded mr-4">
                                            {name.slice(0,1)}{name.slice(-1)}
                                          </FavouriteItemCard>
                                          <div className="flex-1 grid leading-none">
                                              <span className="uppercase">{name}</span>
                                              <span className="font-medium">{value}</span>
                                          </div>
                                    </li>
                                ))}
                            </ul> */}
            </div>
          </>
        );
      }}
    </_Transition>
  );
};

export const Accordion = ({ options, name, children, displayName }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeHeight, setActiveHeight] = useState(null);
  const [inProp, setInProp] = useState(null);

  useEffect(() => {
    const activeElem = activeAccordion?.current;
    console.log("activeElem", activeElem);
    if (activeElem instanceof HTMLElement) {
      let height = expandSection(activeElem);
      setActiveHeight(height);
    }
  }, [activeIndex]);
  const activeAccordion = useRef(null);

  children = [].concat(children);
  children = children.filter((c) => typeof c != "undefined");
  if (children.length == 0) return <></>;
  console.log("accordion children", children);
  //if(!children.every(c => c?.type?.name == "AccordionItem")) throw new Error('Accordion only accepts children of type AccordionItem');

  function expandSection(element) {
    var sectionHeight = element.scrollHeight;
    return sectionHeight;
  }

  return (
    <div className="accordion">
      {name && <h1 className="accordion__title font-bold uppercase">{name}</h1>}
      <dl className="accordion__list">
        {children.map((child, index) => {
          const showDescription =
            index === activeIndex ? "show-description" : "";
          const fontWeightBold =
            index === activeIndex ? "font-weight-bold" : "";
          const ariaExpanded = index === activeIndex ? "true" : "false";
          const ref = index == activeIndex ? activeAccordion : null;
          let inProp = index == activeIndex && activeHeight != null;
          let itemHeight = activeHeight;

          return React.cloneElement(child, {
            key: index,
            activeRef: ref,
            inProp: inProp,
            itemHeight: itemHeight,
            showDescription: showDescription,
            fontWeightBold: fontWeightBold,
            ariaExpanded: ariaExpanded,
            index: index,
            onClick: () => {
              if (index == activeIndex) return;
              setActiveIndex(index);
              setActiveHeight(null);
            },
          });
        })}
      </dl>
    </div>
  );
};

// export default Accordion;

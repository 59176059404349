import React, {useEffect, useState, useContext} from 'react';
import formatter from "../util/formatter";
import ChevronDownIcon from '../icons/ChevronDownIcon';
import actions from '../actions';
import icons from "../icons"
import { useLocation } from 'react-router-dom';
import ChipItem from './ChipItem';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/solid'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import uuid from 'react-uuid'
import ReactJson from 'react-json-view';
import renderComponent from '../renderComponent';
import appFetch from '../appFetch';
import apiEndpoints from '../apiEndpoints';
import WithLoader from './WithLoader';
import EmptyRecord from './EmptyRecord';
import { appendQueryToUri } from '../util/generalUtil';
import useMenuUpdate from '../hooks/useMenuUpdate';
import AppContext from '../contexts/AppContext';
import { CheckIcon, PencilIcon, XIcon } from '@heroicons/react/solid';
import { useTheme } from 'styled-components';

const MySwal = withReactContent(Swal.mixin({
    customClass: {
      confirmButton: 'text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary',
      cancelButton: 'text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary'
    },
    buttonsStyling: false
  }))

function GridCard(props) {
    const {vendor, qty,amount,items,actions} = props;
    return (
        <div className='shadow-md drop-shadow py-4 pl-4 mb-4 rounded'>
            <div className='relative overflow-hidden'>
                <div>
                    <span className="font-semibold">{vendor}</span>
                    <div className="flex gap-1">
                        {items && items.map(({name},idx) => (
                            <span key={idx} class="bg-gray-300 text-sm text-black rounded p-3/5">{name}</span>
                        ))}
                    </div>
                    <div className=''>
                        <span class="font-semibold">Qty:&nbsp;</span>
                        <span>{qty}</span>
                    </div>
                </div>
                <div className='absolute right-0 transform top-3 border py-2 px-5 -mr-3 rounded-xl'>
                    <span className='font-bold'>
                        {amount}
                    </span>
                </div>
            </div>
            <div className="flex justify-between items-center mt-2">
                    <div className='flex gap-2'> 
                        <div class="inline-grid place-items-center w-6 h-6 rounded-xl" style={{backgroundColor:"#FFDEF5"}}>
                            <ChevronDownIcon width={11} height={6} stroke="#B24190"    />
                        </div>
                        <span className='text-sm'>Show More</span>
                    </div>
                    <div className='flex gap-2 pr-3'>
                        {actions && actions.map(({type},idx) => (
                            <div key={idx} className='flex items-center gap-2 rounded-md shadow px-3 py-1' style={{backgroundColor:"#F5F5F5"}}> 
                                {React.createElement(icons[`${type}Icon`])}
                                <span>{type}</span>
                             </div>
                        ))}
                    </div>
                   
            </div>
        </div>
    );
}

function GridList({data,setTransactionType}){
    return (
        <ul>
            {data && data.map((item,idx) => {
                let {name,amount,count} = item;
                return (
                <li className='flex items-center border-b border-gray-200 py-2 mb-3 cursor-pointer' key={idx} onClick={() => setTransactionType(item,idx)}>
                    <div className='border grid place-items-center rounded w-12 h-12'>
                        <span className='text-md'>{count}</span>
                    </div>
                    <div className="flex-auto flex-shrink-0 ml-3">{name}</div>
                    <div class="text-lg font-semibold mr-3" dangerouslySetInnerHTML={{__html: amount}}/>
                    <ArrowRightIcon stroke="#888888" width={14} height={19} />
                </li>
            )})}
        </ul>
    )
}

function PendingApproval({options, name, setLoading}){
    
    const [groups, setGroups] = useState(null);
    const [groupDrill, setGroupDrill] = useState(null);
    // let drill = ((groupDrill ?? groups ?? [])[activeIndex] || {})?.groups

    const [groupListIndex,setGroupListIndex] = useState(0);
    const [groupDrillIndex,setGroupDrillIndex] = useState(0);

    let drill = ((groups ?? [])[groupListIndex] || {})?.groups
    const groupList = groups
    let menuId = (groups || [])[groupListIndex]?.menu_id
    
    const {context,setContext} = useContext(AppContext)
    const {updateMenu} = useMenuUpdate()

    const [selectedGrid, setSelectedGrid] = useState(null);
    
    // const [activeIndex,setActiveIndex] = useState(0);

    useEffect(() => {
        setGroups(options)
        setGroupDrill(null)
    }, [name])

    const setActive = (idx, loadDrill = true) => {
        if(groupDrill)
        { 
          if(idx === groupDrillIndex) return;
          setTransactionType(drill[idx], idx)
          return;          
        }

        if(idx === groupListIndex) return;
        setGroupListIndex(idx)

    }

    const setTransactionType = (item, activeIdx) => {
        // fetch("home")

        setLoading(true)

        let data = {
            "urlMenu":null,
            "layoutId": item?.layoutid,
            "targetId": item?.targetid,
            "target": item?.target?.toUpperCase(),
            "schemaId": item?.schemaid,
            "txnGroup": item?.txn_group,
            "parentDropValue":null,
            "targetType":item?.target_type
        }

        appFetch.get(appendQueryToUri(apiEndpoints.menuView,data)).then(result => {
            setGroupDrill(result)
            setGroupDrillIndex(activeIdx)
        })
        .catch(e => console.log(e.message))
        .finally(() => setLoading(false))
    }

    const buildEditData = (postData, editData) => {
        let ref = postData;
        let count = 1;
        let curr = `ID_ROOT_${count}`;
        let prop = ""
        while(true){
          prop = editData[`ID_ROOT_${count}`];
    
          //check if next exist
          if(typeof editData[`ID_ROOT_${count+1}`] == "undefined") break
    
          ref[prop] = typeof ref[prop] != "undefined" ? ref[prop] : {}
          ref = ref[prop];
    
          count++;
        }
    
        ref[prop] = ref[prop] ? [].concat(ref[prop],editData[prop]) : editData[prop]
        return postData;
      }

    const handleMultiButtonAction = (button) => {
        let selectedCheckBoxes = Array.from(document.querySelectorAll('.transaction-line input:checked')).map(m => m.id)
        let selectedItems = groupDrill?.options?.filter(m =>  selectedCheckBoxes.includes(`check-control-${m.targetid}`));

        let validSelectedItems = [].concat(selectedItems)
        let buttonData = {}
        validSelectedItems.forEach(m => {
            buttonData = buildEditData(buttonData, m.edit_header[0])
        })
        
        console.log({validSelectedItems,buttonData});
        if(validSelectedItems.length > 0){
            let headerIds = []
            
            let postData = {
                "jsonString": JSON.stringify([buttonData]),
                "menuId": menuId,
                "pageButtonActionId": null,
                "buttonAction": button.value
              }
      
              setLoading(true)
              appFetch.post(apiEndpoints.menuAction, {
                  "body":JSON.stringify(postData),
              }).then(result => {
                  console.log("wine =>=>",result)
                  let {ERROR_MESSAGE:errMsg,ERROR_NUMBER:errNo} = result;
            
                  MySwal.fire({
                    text:errMsg,
                    icon: errNo === 0 ? "success" : "error",
                  })
            
                  if(errNo !== 0) {
                    throw new Error(errMsg);
                  }
      
                  let page = {...context.pageConfig};
                  let [leftArea,MainArea,rightArea] = page.children;
                  let mainApprovalArea = result?.layout.children[1];
                  page.children = [leftArea, mainApprovalArea, rightArea];
      
      
                  updateMenu(result?.MENU_BADGE)
                  typeof setGroupDrill === "function" && setGroupDrill(null)
                  typeof setGroups === "function" && setGroups(mainApprovalArea.children[0].options)
      
                  //run this block if page is entered via menu link
                  if(!setGroupDrill) {
                      setContext(prev => ({...prev, pageConfig:page}))
                  }
              })
              .catch(err => console.log(err))
              .finally(() => setLoading(false));
        }
    }

    const lines = groupDrill?.options?.length || 0
    const {multibutton} =  groupDrill || {}

    return (
        <>
            <div className='flex justify-between'>
                <header className="uppercase text-xl mb-6">{name}</header>
               {groupDrill && <button 
                class="tracking-normal font-semibold text-sm py-2 px-2 rounded inline-flex items-center"
                onClick={() => {
                    setGroupDrill(null)
                    setGroupListIndex(groupListIndex)
                }}>
                    <svg width="14" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.46154 14L2 8L8.46154 2M2.89744 8H16" stroke="#aaaaaa" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span className="ml-1 text-black-400">Back</span>
                </button>
                }
            </div>
            <div className='flex justify-between lg:flex-row flex-col mb-4 flex-nowrap overflow-auto'>
                <div className='flex gap-2 flex-nowrap overflow-auto'>
                    {(groupDrill ? drill : groupList)?.map(({...rest}, idx) => (
                        <ChipItem 
                        key={idx} 
                        index={idx} 
                        active={idx === (groupDrill ? groupDrillIndex : groupListIndex)} 
                        {...rest} 
                        groupDrill={groupDrill}
                        handleMultiButtonAction={handleMultiButtonAction}
                        setActive={setActive} />
                    ))}
                </div>
                {lines > 1 && 
                <div className='flex lg:flex-row flex-col gap-2 lg:mt-0 mt-2'>
                    {multibutton?.map(props => {
                        switch(props.action_type){
                            case "Approve":
                                return <MultiButtonApprove {...props} handleClick={handleMultiButtonAction} />
                            case "Memo":
                                return <MultiButtonMemo {...props} handleClick={handleMultiButtonAction} />
                            case "Reject":
                                return <MultiButtonReject {...props} handleClick={handleMultiButtonAction} />            
                            default :
                                return <></>
                        }
                    })}
                </div>}
            </div>

            {!(groupList?.length > 0) &&  
                <EmptyRecord>
                    <h2 className='font-semibold text-md'>No transactions available</h2>
                    <p className='text-sm text-gray-700 font-light'>Check back in a bit!</p>
                </EmptyRecord>
            }
            {groupDrill ? 
            <>
                {renderComponent({menuId, ...groupDrill, setGroups,setGroupDrill,handleMultiButtonAction})}
            </> : 
             <GridList data={drill} setTransactionType={setTransactionType}/> }
        </>
    )
}

const MultiButtonApprove = (props) => {
    const theme = useTheme()
    const {name, handleClick} = props
    return (
        <button className="border-primary border rounded-md px-3 py-1 flex items-center gap-2" onClick={e => handleClick(props)}>
            <CheckIcon fill={theme?.colors.primary} width={24} />
            <span className='text-primary text-xs'>{name}</span>
        </button>
    )
}

const MultiButtonMemo = (props) => {
    const theme = useTheme()
    const {name, handleClick} = props
    return (
        <button className="border-primary border rounded-md  px-3 py-1 flex items-center gap-2" onClick={e => handleClick(props)}>
            <PencilIcon fill={theme?.colors.primary} width={24} />
            <span className='text-primary text-xs'>{name}</span>
        </button>
    )
}

const MultiButtonReject = (props) => {
    const theme = useTheme()
    const {name, handleClick} = props
    return (
        <button className="bg-primary rounded-md  px-3 py-1 flex items-center gap-2" onClick={e => handleClick(props)}>
            <XIcon fill="#ffffff" width={24} />
            <span className='text-white text-xs'>{name}</span>
        </button>
    )
}

export default WithLoader(PendingApproval);
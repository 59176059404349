import React from 'react';
import icons from "../icons"

function TopNavActionButton({name,icon}) {
    return (
        <div>
            {icons[icon] && 
            React.createElement(icons[icon], {
                fill:"#000000"
            })}
        </div>
    );
}

export default TopNavActionButton;
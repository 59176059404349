

let base = "";

// if (process.env.NODE_ENV === 'production') base = process.env.REACT_APP_PRODUCTION_API
// if (process.env.NODE_ENV === 'development') base = process.env.REACT_APP_DEVELOPMENT_API

// console.log("base => ", base);

const home = "/home"
const home2 = "/home2"
const graphData = "/graphData"

const newTransaction = "/transaction"
const assets = "/assets"
const assetDisposal = "/assets/asset-disposal"
const pendingTransaction = "/home/pending-transaction"
const openTransaction = "/home/open-transaction"
const pendingTask = "/home/pending-task"
const sales  = "/home/pending-transaction/sales"

const setup = "/setup"
const approvals = "/approvals"
const open = "/open"
const report = "/report"
const setting = "/setting"


const endpoints = {
    home,
    home2,
    graphData,
    newTransaction,
    assets,
    assetDisposal,
    pendingTransaction,
    openTransaction,
    pendingTask,
    sales,
    setup,
    approvals,
    open,
    report,
    setting
} 
for(let p in endpoints) endpoints[p] = base+endpoints[p]; 

export default endpoints;


let base = ""
if (process.env.NODE_ENV === 'production') base = process.env.REACT_APP_THEPLACE_VENDOR_SERVICE
if (process.env.NODE_ENV === 'development') base = process.env.REACT_APP_DEVELOPMENT_API
if (process.env.REACT_APP_ENV === 'staging') base = process.env.REACT_APP_STAGING_API
if (process.env.REACT_APP_ENV === 'development') base = process.env.REACT_APP_DEVELOPMENT_API
if (process.env.REACT_APP_ENV === 'practice') base = process.env.REACT_APP_PRACTICE_API
if (process.env.REACT_APP_ENV === "local") base = process.env.REACT_APP_LOCAL_API
if (process.env.REACT_APP_ENV === "loanapp") base = process.env.REACT_APP_LOAN_SERVICE
if (process.env.REACT_APP_ENV === "theplace") base = process.env.REACT_APP_THEPLACE_API

base = "https://vendorservice.theplace.com.ng"

const login = "user/login"
const logout = "user/logout"
const menuView = "app/menu-view"
const menuAction = "app/menu-action"
const dropdownView = "app/dropdown-view"
const fileUpload = "app/file-upload"
const uploadData = "app/upload-data" 
const downloadData = "app/download-data" 
const changePassword = "user/change-password"
const printAction = "app/print-action"
const forgotPassword = "user/forgot-password"
const sendOtp = "app/send-otp"
const getAppCache = "app"
const getServerProps = "app/get-server-props"

const apiEndpoints = {
    login,
    logout,
    menuView,
    menuAction,
    dropdownView,
    fileUpload,
    changePassword,
    printAction,
    forgotPassword,
    sendOtp,
    getAppCache,
    uploadData,
    downloadData,
    getServerProps
}

for(let endpoint in apiEndpoints){
    apiEndpoints[endpoint] = `${base}/${apiEndpoints[endpoint]}`
}

export default apiEndpoints;


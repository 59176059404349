import React from 'react';
import { useSubscriber } from '../hooks/useSubscriber';

function NotAllowed(props) {
    const { subs } = useSubscriber()

    const appOrigin = window.location.origin

    return (
        <div className='grid place-items-center h-full w-full bg-white'>
            <div>
                    <p>{`${appOrigin}${subs ? `/${subs}` : ''}`} is not a valid address.</p>
                    <a href='mailto:support@hts.com.ng' className='text-primary'>
                        Contact Admin
                    </a>
                </div>
            </div>
    );
}

export default NotAllowed;
import React, { Fragment, useContext, useEffect, useState } from "react";
import ReactJson from "react-json-view";
import {
  SelectField,
  RadioField,
  LabelField,
  RichTextField,
  SelectField2,
  InputField2,
  FileField2,
  FileField,
  DownloadField,
  PhoneField,
  StyledDate,
  NumberField,
  InputField,
} from "./FormElements";
import { Formik, Form as FormikForm, Field, useFormikContext } from "formik";
import { update } from "draft-js/lib/DefaultDraftBlockRenderMap";
import PrintPreview from "./PrintPreview";
import { Dialog, Transition } from "@headlessui/react";
import styled, { useTheme } from "styled-components";
import appFetch from "../appFetch";
import apiEndpoints from "../apiEndpoints";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Spinner from "../loaders/Spinner";
import ImagePlaceholder from "../assets/images/image-placeholder.svg";
import classNames from "classnames";
import AppContext from "../contexts/AppContext";
import useMenuUpdate from "../hooks/useMenuUpdate";
import TransformValue from "./TransformValue";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import { mapValidationSchema } from "../formValidation";
import * as Yup from "yup";
import { defaults } from "../mapInitialValues";
import OtpControl from "./OtpControl";
import { saveAs } from "file-saver";
import moment from "moment";
import { mapOperator } from "../util";
import { formatNumber } from "../util/formatter";
import { appendQueryToUri } from "../util/generalUtil";
import ConfirmButton from "./ConfirmButton";
import DrillModalDialog from "./DrillModalDialog"

const MySwal = withReactContent(
  Swal.mixin({
    customClass: {
      confirmButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
      cancelButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
    },
    buttonsStyling: false,
  })
);

function mapField(type) {
  switch (type) {
    case "select":
    case "multiselect":
      return SelectField;
    case "radio":
      return RadioField;
    case "dropdown":
      return SelectField;
    case "label":
      return LabelField;
    case "richtext":
      return RichTextField;
    case "phonenumber":
      return PhoneField;
    case "date":
    case "datetime":
      return StyledDate;
    case "number":
      return NumberField;
    case "upload":
      return FileField2;
    case "download":
      return DownloadField;
    default:
      return InputField;
  }
}

const buildEditData = (postData, editData) => {
  console.log("editData",editData)
  let ref = postData;
  let count = 1;
  let curr = `ID_ROOT_${count}`;
  let prop = "";
  while (true) {
    prop = editData[`ID_ROOT_${count}`];

    //check if next exist
    if (typeof editData[`ID_ROOT_${count + 1}`] == "undefined") break;

    ref[prop] = typeof ref[prop] != "undefined" ? ref[prop] : {};
    ref = ref[prop];

    count++;
  }

  ref[prop] = editData[prop];
  return postData;
};

const CommentModal = ({ isOpen, setIsOpen, buttonData, updateData }) => {
  let { data, targetid, buttonAction, buttonName } = buttonData;
  const [processing, setProcessing] = useState(false);
  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      className="fixed z-10 inset-0 overflow-y-auto"
    >
      <div className="flex items-center justify-center min-h-screen h-full">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="relative bg-white mx-auto my-10 rounded-md">
          <div className="p-6 pt-14">
            <button
              className="absolute right-4 top-4"
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.9651 10.4985L20.4829 2.99719C20.8121 2.66793 20.997 2.22136 20.997 1.75571C20.997 1.29007 20.8121 0.843497 20.4829 0.514237C20.1536 0.184977 19.7071 0 19.2416 0C18.776 0 18.3295 0.184977 18.0003 0.514237L10.5 8.03304L2.99975 0.514237C2.67053 0.184977 2.22403 -3.46932e-09 1.75845 0C1.29287 3.46932e-09 0.846361 0.184977 0.517147 0.514237C0.187934 0.843497 0.00298367 1.29007 0.00298367 1.75571C0.00298366 2.22136 0.187934 2.66793 0.517147 2.99719L8.03488 10.4985L0.517147 17.9998C0.353281 18.1624 0.223217 18.3558 0.134458 18.5688C0.0456982 18.7819 0 19.0105 0 19.2413C0 19.4721 0.0456982 19.7007 0.134458 19.9138C0.223217 20.1268 0.353281 20.3202 0.517147 20.4828C0.679675 20.6467 0.87304 20.7768 1.08609 20.8655C1.29914 20.9543 1.52765 21 1.75845 21C1.98925 21 2.21776 20.9543 2.43081 20.8655C2.64386 20.7768 2.83722 20.6467 2.99975 20.4828L10.5 12.964L18.0003 20.4828C18.1628 20.6467 18.3561 20.7768 18.5692 20.8655C18.7822 20.9543 19.0108 21 19.2416 21C19.4723 21 19.7009 20.9543 19.9139 20.8655C20.127 20.7768 20.3203 20.6467 20.4829 20.4828C20.6467 20.3202 20.7768 20.1268 20.8655 19.9138C20.9543 19.7007 21 19.4721 21 19.2413C21 19.0105 20.9543 18.7819 20.8655 18.5688C20.7768 18.3558 20.6467 18.1624 20.4829 17.9998L12.9651 10.4985Z"
                  fill="#747474"
                />
              </svg>
            </button>
            <div className="">
              <Formik
                initialValues={{ APPROVAL_MEMO: "" }}
                onSubmit={async (values, { resetForm }) => {
                  let newData = { ...data };
                  newData["_HEADER"] = { ...newData["_HEADER"], ...values };
                  setProcessing(true);
                  await updateData({ data: newData, targetid, buttonAction });
                  setProcessing(false);
                  setIsOpen(false);
                }}
              >
                <FormikForm className="grid">
                  <Field
                    as="textarea"
                    rows={6}
                    cols={50}
                    name="APPROVAL_MEMO"
                    autoComplete="off"
                    placeholder="add comment"
                    className="pl-3 placeholder-custom-gray-100 placeholder-opacity-40 appearance-none relative block w-full px-3 py-2 border border-custom-gray-300 text-gray-500 rounded-md focus:outline-none sm:text-sm"
                  />
                  <button className="mt-3 bg-primary text-white py-2 rounded-md inline-block self-end inline-flex justify-center">
                    {processing ? (
                      <Spinner width={20} height={20} fill="#f7f7f7" />
                    ) : (
                      buttonName
                    )}
                  </button>
                </FormikForm>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const BidModal = ({
  isOpen,
  setIsOpen,
  bidOptions,
  setBidOptions,
  actions,
  getInitialValues,
  getObjectValue,
}) => {
  const updateButton = {
    name: "Update",
    value: "Update",
    action: "modal",
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {}}
      className="fixed z-10 inset-0 overflow-y-auto"
    >
      <div className="flex items-center justify-center min-h-screen h-full">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
        <div className="relative bg-white mx-auto my-10">
          <div className="p-2">
            <button
              onClick={() => {
                setIsOpen(false);
                setBidOptions(null);
              }}
            >
              Close
            </button>

            <EditableTableRow
              item={{ ...bidOptions, button: [bidOptions.buttonItem] }}
              getInitialValues={getInitialValues}
              getObjectValue={getObjectValue}
              actions={actions}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const OtpModal = ({
  isOpen,
  otpPhone,
  closeModal,
  sendOtp,
  otherProps,
  actions,
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <OtpControl
                  closeModal={closeModal}
                  otpPhone={otpPhone}
                  sendOtp={sendOtp}
                  otherProps={otherProps}
                  actions={actions}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const ButtonStyle = styled.button`
  background: #f5f5f5;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 4px 0px 0px 4px;
`;

const ButtonStyle2 = styled.button`
  color: #5d5d5d;
`;

const sendOtp = (props) =>
  new Promise((resolve, reject) => {
    const { menuId, value: buttonAction, headerControl, ...rest } = props;

    let jsonData = {};
    jsonData = buildEditData(jsonData, headerControl);

    let postData = {
      jsonString: JSON.stringify(jsonData),
      menuId: props.menuId,
      pageButtonActionId: null,
      buttonAction: buttonAction,
    };

    appFetch
      .post(apiEndpoints.sendOtp, {
        body: JSON.stringify(postData),
      })
      .then((result) => {
        console.log("result from otp sent", result);
        let { ERROR_MESSAGE: errMsg, ERROR_NUMBER: errNo } = result;

        if (errNo !== 0) {
          MySwal.fire({
            text: errMsg,
            icon: "error",
          });
          throw new Error(errMsg);
        }

        resolve(result);
      })
      .finally(() => resolve(true));
  });

const fetchModalDetails = (props) =>
new Promise((resolve, reject) => {
  const { menuId, value: buttonAction, headerControl, ...rest } = props;

  let jsonData = {};
  jsonData = buildEditData(jsonData, headerControl);

  console.log("the value of props here", props, buttonAction);

  let postData = {
    layoutId: props.layout_id,
    drillId: props.drill_id,
    target: props.action_type?.toUpperCase(),
    targetType: props.action_type?.toUpperCase(),
    targetName: buttonAction
  };

  appFetch
    .get(appendQueryToUri(apiEndpoints.menuView, postData))
    .then((result) => {
      console.log("value of payroll variance", result);
      // let { ERROR_MESSAGE: errMsg, ERROR_NUMBER: errNo } = result;

      // if (errNo !== 0) {
      //   MySwal.fire({
      //     text: errMsg,
      //     icon: "error",
      //   });
      //   throw new Error(errMsg);
      // }

      resolve(result);
    })
    .finally(() => resolve(true));

});

const TransactionLineButton = (props) => {
  const [processing, setProcessing] = useState(false);
  const [otpModal, showOtpModal] = useState(false);
  const [btnDetailsModal, showBtnDetailsModal] = useState(false);
  const [btnDetailsModalData, setBtnDetailsModalData] = useState(null);
  const { context } = useContext(AppContext);
  const [otpPhone, setOtpPhone] = useState(null);
  const { menuId } = context;

  const buttonClick = async () => {
    setProcessing(true);

    if (props.button_otp?.toLowerCase() == "yes") {
      let response = await sendOtp({ menuId, ...props });
      setProcessing(false);
      if (response) {
        setOtpPhone(response.userPhoneNumber);
        setTimeout(() => showOtpModal(true), 300);
      }
      return;
    }

    if (props.action_type?.toLowerCase() == "modal") {
      let response = await fetchModalDetails({ menuId, ...props });
      console.log("response from fetchModalDetails",response)
      setProcessing(false);
        setBtnDetailsModalData(response);
        setTimeout(() => showBtnDetailsModal(true), 300);
      return;
    }

    setProcessing(true);
    await actions({
      name,
      values,
      submitForm,
      targetid,
      controls: editable_controls,
      headerControl,
      lines,
      value,
      item,
      validateForm,
      setErrors,
      setTouched,
    });
    setProcessing(false);
  };

  let {
    button_otp,
    values,
    submitForm,
    targetid,
    editable_controls,
    headerControl,
    lines,
    value,
    name,
    actions,
    item,
    validateForm,
    setErrors,
    setTouched,
  } = props;

  console.log("this is the print props", props);
  //   <PrintPreview data={item} menuId={menuId} />;
  return (
    <>
      {otpModal && (
        <OtpModal
          isOpen={otpModal}
          otpPhone={otpPhone}
          otherProps={{ ...props, menuId }}
          actions={actions}
          sendOtp={sendOtp}
          closeModal={() => showOtpModal(false)}
        />
      )}
      {btnDetailsModal && <DrillModalDialog isOpen={btnDetailsModal} closeModal={() => showBtnDetailsModal(false)} item={btnDetailsModalData} />}
      {["approve", "reject", "delete", "update", "memo"].includes(
        name?.toLowerCase()
      ) ? (
        <ButtonStyle 
          as={ConfirmButton}
          action={name?.toLowerCase()}
          className="shadow-md bg-gray-100 p-2 px-3 rounded-md text-xs font-semibold inline-flex justify-center self-start"
          disabled={processing}
          onClick={buttonClick}
        >
          {processing ? (
            <Spinner width={16} height={16} fill="#aaaaaa" />
          ) : (
            name
          )}
        </ButtonStyle>
      ) : (
        <ButtonStyle
          //   as={PrintPreview}
          //   data={props}
          className="shadow-md bg-gray-100 p-2 px-3 rounded-md text-xs font-semibold inline-flex justify-center self-start"
          disabled={processing}
          onClick={buttonClick}
        >
          {processing ? (
            <Spinner width={16} height={16} fill="#aaaaaa" />
          ) : (
            name
          )}
        </ButtonStyle>
      )}
    </>
  );
};

const FormWithContext = ({
  props,
  otherProps,
  header,
  lines,
  hasDownload,
  hasUpload,
  _controls,
  editable_controls,
  button,
  collapseSection,
  hasInnerOptions,
}) => {
  const {
    values,
    handleChange,
    setFieldValue,
    setValues,
    setStatus,
    status,
    validateForm,
    setErrors,
    setTouched,
    submitForm,
  } = props;
  const theme = useTheme();
  let computeEvents = {};
  for (let control of _controls) {
    let { validation, name, compute, options } = control;
    if (compute instanceof Object) {
      let { operator, withField } = compute;
      let reduceFunc = mapOperator(operator);

      lines.forEach((m, idx) => {
        let withFields = withField.map((m) => `${m}_line_${idx + 1}`);
        for (let field of withFields) {
          let func = (() => {
            let _name = `${name}_line_${idx + 1}`;
            let fields = [...withFields];
            return (e) => {
              let { value: fieldValue, name: fieldName } = e.target;
              let currVals = {};
              currVals[fieldName] = fieldValue;

              let computeValues = fields.map((f) => {
                if (f === fieldName) {
                  return parseFloat(fieldValue?.replace(/\,/g, ""));
                }
                return parseFloat(values[f]?.toString()?.replace(/\,/g, ""));
              });

              let result = computeValues.reduce(reduceFunc);
              setFieldValue(_name, formatNumber(result) || "");
            };
          })();

          if (!computeEvents[field]) {
            computeEvents[field] = {};
          }

          computeEvents[field].onChange = (
            computeEvents[field]?.onChange ?? []
          ).concat(func);
        }
      });
      control.disabled = true;
    }
  }

  console.log("computeEvents", computeEvents);

  return (
    <>
      <div className="overflow-x-visible w-full tranx-table scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-300">
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              {header?.map((str, idx) => {
                return (
                  <th
                    key={idx}
                    className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                  >
                    {str?.replace(/_/g, " ")}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {lines.map((row, idx) => (
              <>
                <tr>
                  {header?.map((str, idx) => {
                    const editableControl = editable_controls?.find(
                      (c) => c.column === str
                    );
                    let { column, name, parent, ...rest } =
                      editableControl || {};

                    name = `${name}_line_${row["LINE"]}`;
                    if (parent) {
                      parent = `${parent}_line_${row["LINE"]}`;
                    }

                    let value = row[str.toUpperCase()];

                    let events = computeEvents[name] ?? {};
                    //eg: onChange: [] onKeyup: []
                    let eventHandlers = {};
                    Object.keys(events).forEach((eventType) => {
                      //eventType onChange
                      eventHandlers[eventType] = (e) => {
                        //e as target
                        //handleChange(e);
                        events[eventType].forEach((func) => {
                          func(e);
                        });
                      };
                    });

                    console.log("computeEvents2", name, eventHandlers);

                    return (
                      <td className="px-5 py-2 border-gray-200 bg-white text-sm">
                        {editableControl ? (
                          <div>
                            {React.createElement(
                              mapField(editableControl.type),
                              {
                                name,
                                parent,
                                value,
                                useVariableWidth: true,
                                ...eventHandlers,
                                ...rest,
                              }
                            )}
                          </div>
                        ) : (
                          <p
                            className={classNames(
                              "text-gray-900 whitespace-no-wrap text-xs",
                              {
                                "text-primary underline":
                                  str.toLowerCase() == "item",
                              }
                            )}
                          >
                            <TransformValue value={value} />
                          </p>
                        )}
                      </td>
                    );
                  })}
                </tr>
                {hasUpload && row["UPLOAD"] === "Yes" && (
                  <tr>
                    <td colspan={(header?.length || 0) + (hasUpload ? 1 : 0)}>
                      <div className="bg-gray-100 px-6 py-3">
                        <FileField
                          name={`${hasUpload.name}_line_${row["LINE"]}`}
                          label="Upload..."
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex gap-2 justify-between mt-3 mb-1 lg:flex-row flex-col">
        <ButtonStyle
            className="shadow-md bg-gray-100 p-2 px-3 rounded-md text-xs font-semibold flex items-center"
            onClick={collapseSection}
            >
            <ToggleGridButtonIcon className="shadow w-4 h-4 rounded-full inline-grid place-items-center">
              <ChevronUpIcon
                width={16}
                height={16}
                fill={theme?.colors?.primary}
              />
            </ToggleGridButtonIcon>
            <span className="ml-2 text-xs font-medium text-black">
              Collapse
            </span>
            </ButtonStyle>
        <div className="flex gap-2 ">
          {console.log(button)}
          {button?.map((item, idx) => (
            <TransactionLineButton
              validateForm={validateForm}
              setErrors={setErrors}
              setTouched={setTouched}
              {...otherProps}
              {...item}
              {...{editable_controls, values }}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const EditableTableRow = ({
  item,
  getInitialValues,
  getObjectValue,
  actions,
  collapseSection,
}) => {
  let {
    line_header,
    line_data,
    attachment,
    line_control_edit,
    button,
    edit_header,
    targetid,
    parent,
    options: innerOptions,
  } = item;

  //   const menuId = context?.menuId;
  const theme = useTheme();
  let lines = line_data ?? [];
  let header = (line_header || [])[0]?.name ?? [];
  let attachments = (attachment || [])[0]?.name ?? [];
  let editable_controls = (line_control_edit || [])[0]?.name ?? [];
  let hasUpload = editable_controls?.find((m) => m.type == "upload");
  let hasDownload = editable_controls?.find((m) => m.type == "download");

  let headerControl = (parent ?? edit_header ?? [])[0];
  header = header?.filter((str) => !/attachment/i.test(str));
  editable_controls = editable_controls?.filter(
    (row) => !/attachment/i.test(row?.column)
  );

  //

  let _controls = editable_controls?.filter(
    (row) => !/download/i.test(row?.column)
  );

  //remove non-applicable columns before making initialValues object
  let initialValues = getInitialValues(_controls, lines);

  let validationSchema = {};

  for (let control of _controls) {
    let { type, value, name, parent, defaultValue } = control;

    if (/label/i.test(type)) {
      continue;
    }

    let validation = mapValidationSchema(control);
    if (validation) {
      for (let i = 1; i <= lines.length; i++) {
        let propName = `${name}_line_${i}`;
        validationSchema[propName] = validation;
      }
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object(validationSchema)}
    >
      {(props) => {
        return (
          <>
            <FormikForm>
              <FormWithContext
                props={props}
                header={header}
                lines={lines}
                hasInnerOptions={(innerOptions || []).length > 0}
                hasUpload={hasUpload}
                hasDownload={hasDownload}
                _controls={_controls}
                editable_controls={editable_controls}
                button={button}
                collapseSection={collapseSection}
                otherProps={{ targetid, headerControl, lines, actions, item }}
              />
            </FormikForm>
          </>
        );
      }}
    </Formik>
  );
};

const getInitialValues = (controls, lines) => {
  let values = {};
  lines?.forEach((row) => {
    controls?.forEach(({ name, type }) => {
      let newName = `${name}_line_${row["LINE"]}`;
      values[newName] = row[name?.toUpperCase()] ?? defaults(type);
      values[newName] = ["number", "money"].includes(type)
        ? parseFloat(values[newName])
        : values[newName];
    });
  });
  return values;
};

const getObjectValue = (value) => {
  if (Array.isArray(value))
    return (
      <ul className="flex">
        {value?.map((item, idx) => {
          if (/[\/.](gif|jpg|jpeg|tiff|png)$/i.test(item)) {
            return (
              <li className="mr-0.5">
                <div className="w-8 h-8 bg-gray-100 p-0.5 rounded-md">
                  <img
                    src={item ?? ImagePlaceholder}
                    className="rounded-md w-full h-full obect-center object-cover"
                  />
                </div>
              </li>
            );
          }
          return (
            <li>
              <span key={idx}>
                {idx > 0 && <>&amp;</>}
                {item}
              </span>
            </li>
          );
        })}
      </ul>
    );
  return value;
};

function TransactionListLine({
  data,
  setGroupDrill,
  setGroups,
  hasMultiButton,
}) {
  const [previewData, setPreviewData] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [buttonAction, updateButtonAction] = useState(null);
  const [buttonData, updateButtonData] = useState(null);
  const [saving, setSaving] = useState(false);
  const [target, setModalTarget] = useState(null);
  const [bidModal, setBidModal] = useState(false);
  const [bidOptions, setBidOptions] = useState(false);
  const { context, setContext } = useContext(AppContext);
  const menuId = context?.menuId;
  const { updateMenu } = useMenuUpdate();

  const actions = async ({
    submitForm,
    values,
    targetid,
    controls,
    headerControl,
    lines,
    name,
    value,
    item,
    validateForm,
    setErrors,
    setTouched,
    codes,
  }) => {
    value = value?.toString()?.toUpperCase();
    name = name.toUpperCase();
    var postData = {};
    postData = buildEditData(postData, headerControl);
    console.log("values", values, controls,name);

    switch (name) {
      case "APPROVE":
      case "MEMO":
      case "REJECT":
        updateButtonData({
          data: postData,
          targetid,
          buttonAction: value,
          buttonName: name,
        });
        setCommentModalOpen(true);
        return;
      case "DOWNLOAD":
        let newData = { ...postData };
        newData["_HEADER"] = { ...newData["_HEADER"], USER_OTP: codes };
        let result = await updateData({
          data: newData,
          targetid,
          buttonAction: value,
          buttonName: name,
        });
        return result;
      case "PRINT":
        var res = await printAction(item);
        return res;
      // default:
      //     return await updateData({data:postData,targetid,buttonAction:value,buttonName:name});
      default:
        //validate form if exists
        try {
          let formSubmission = await validateForm();
          console.log("formSubmission",formSubmission)
          if (Object.keys(formSubmission).length) {
            setTouched(formSubmission);
            return;
          }
        } catch {}

        console.log("value of controls", controls);
        lines?.forEach((row) => {
          var data = {};
          for (let control of controls) {
            let { name, type, response } = control;

            if (/label/i.test(type)) {
              continue;
            }

            let ref = data;
            let count = 1;
            let curr = `root_${count}`;
            let prop = "";
            while (true) {
              prop = control[`root_${count}`];

              //check if next exist
              if (typeof control[`root_${count + 1}`] == "undefined") break;

              ref[prop] = ref[prop] || {};
              ref = ref[prop];

              count++;
            }

            if(!prop) continue;
            let newName = `${name}_line_${row["LINE"]}`;

            console.log('current point',values)
            let formValue = values[newName];

            if (type === "number") {
              formValue = formValue?.toString()?.replace(/,/g, "");
            }

            if (Array.isArray(formValue)) {
              formValue = formValue?.map((m) => m?.value).join("");
            }

            if ({}.constructor === formValue?.constructor) {
              formValue = formValue?.value;
            }

            if (moment.isDate(formValue)) {
              formValue = moment(formValue).format("YYYY-MM-DD h:mmA");
            }

            formValue =
              response === "array"
                ? [].concat(formValue).filter((m) => m)
                : formValue;
            ref[prop] = formValue;
          }

          let lineProp = Object.keys(data).slice(-1)[0];
          console.log("data from transaction line", data, postData);

          if (lineProp) {
            data[lineProp]["LINE"] = row["LINE"];
            postData[lineProp] = (postData[lineProp] ?? []).concat(
              data[lineProp]
            );
          }
        });
        return await updateData({
          data: postData,
          targetid,
          buttonAction: value,
          buttonName: name,
        });
    }
  };

  console.log("the data is", data);

  const printAction = (data) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        setIsOpen(true);
        setPreviewData(data);
        resolve(true);
      }, 1000);
    });

  //Do not, that @page_button_action_id it is reserved for either pageid or action id
  const updateData = ({ data, targetid, buttonAction }) =>
    new Promise((resolve, reject) => {
      let postData = {
        jsonString: JSON.stringify([data]),
        menuId: menuId,
        pageButtonActionId: null,
        buttonAction: buttonAction,
      };

      console.log("postData =>=>", postData);
      
      appFetch
        .post(apiEndpoints.menuAction, {
          body: JSON.stringify(postData),
        })
        .then((result) => {
          console.log("wine =>=>", result);
          let { ERROR_MESSAGE: errMsg, ERROR_NUMBER: errNo } = result;

          MySwal.fire({
            text: errMsg,
            icon: errNo === 0 ? "success" : "error",
          });

          if (errNo !== 0) {
            throw new Error(errMsg);
          }

          // if(buttonAction === "UPDATE"){
          //     return;
          // }

          if (Object.keys(result.DOWNLOAD_LINK || []).length > 0) {
            let fileName = result.DOWNLOAD_LINK;
            let extension = (/\.[0-9a-z]+$/i.exec(fileName) ?? [])[0] ?? "";
            saveAs(fileName, "pms_file_10" + extension);
          }

          let page = { ...context.pageConfig };
          let [leftArea, MainArea, rightArea] = page.children;
          let mainApprovalArea = result?.layout.children[1];
          page.children = [leftArea, mainApprovalArea, rightArea];

          resolve(result);

          console.log("wine =>=>", result);
          updateMenu(result?.MENU_BADGE);
          typeof setGroupDrill === "function" && setGroupDrill(null);
          typeof setGroups === "function" &&
            setGroups(mainApprovalArea.children[0].options);

          //run this block if page is entered via menu link
          if (!setGroupDrill) {
            setContext((prev) => ({ ...prev, pageConfig: page }));
          }
        })
        .catch(() => resolve(false));
      //.finally(() => resolve(true))
    });

  const updateBidOptions = ({ options, name, buttonItem }) => {
    console.log("updating bid options",options,name, buttonItem);
    if (/print/i.test(name)) {
      setIsOpen(true);
      setPreviewData(options);
      return;
    }

    setBidModal(true);
    // setBidOptions(options);
    setBidOptions({ ...options, buttonItem:buttonItem })
  };

  const closeModal = () => setIsOpen(false);

  return (
    <>
      {bidModal && (
        <BidModal
          setIsOpen={setBidModal}
          isOpen={bidModal}
          bidOptions={bidOptions}
          setBidOptions={setBidOptions}
          actions={actions}
          getInitialValues={getInitialValues}
          getObjectValue={getObjectValue}
        />
      )}
      {commentModalOpen && (
        <CommentModal
          buttonData={buttonData}
          setIsOpen={setCommentModalOpen}
          isOpen={commentModalOpen}
          updateData={updateData}
        />
      )}

      {previewData && (
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => {}}
          open={isOpen}
        >
          <div className="min-h-screen h-full px-4 text-center">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
            <span
              className="inline-block h-full align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block max-w-6xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white rounded-2xl">
              <PrintPreview data={previewData} menuId={menuId} />
              <div className="mt-4 flex justify-end">
                <button
                  type="button"
                  className="pointer inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-primary border border-transparent rounded-md"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      )}
      
      <div className="grid gap-6">
        {data &&
          data.map((item, idx) => (
            <LineGrid
              actions={actions}
              lines={data.length}
              hasMultiButton={hasMultiButton}
              updateBidOptions={updateBidOptions}
              key={idx}
              item={item}
            />
          ))}
      </div>
    </>
  );
}

const GridStyle = styled.div`
  background: #fbfbfb;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
`;

const ToggleGridButtonIcon = styled.div`
  background: ${({ theme }) => theme.colors.accent};
  color: ${({ theme }) => theme.colors.primary};
`;

const LineGrid = ({
  item,
  actions,
  updateBidOptions,
  lines,
  hasMultiButton,
}) => {
  let { targetid, summary, options, button } = item;
  const theme = useTheme();
  const [activeItem, setActiveItem] = useState(null);
  const [processing, setProcessing] = useState(false);

  //override the button with active item to maintain single line
  button = activeItem?.button || button

  // useEffect(() => {
  //   setActiveItem(null);
  // }, [targetid]);

  const getActiveItem = (item) => {
    let postData = {
      urlMenu: null,
      layoutId: item.layoutid,
      targetId: item.targetid,
      target: item.target,
      schemaId: null,
      txnGroup: null,
      parentDropValue: null,
      drillId: null,
      targetName: null,
      targetType: null,
    };

    setProcessing(true);
    appFetch
      .get(appendQueryToUri(apiEndpoints.menuView, postData))
      .then((res) => {
        setActiveItem(res?.options[0]);
      })
      .catch((e) => console.log(e.message))
      .finally(() => setProcessing(false));
  };
  return (
    <GridStyle className="transaction-line p-7 relative overflow-hidden">
      <div className="flex justify-between">
        <div className="flex gap-4 flex-1">
          <div
            className="border px-3 rounded-lg grid place-items-center"
            style={{ backgroundColor: "#F3F3F3" }}
          >
            <span className="text-md font-bold inline-grid place-items-center">
              {console.log("This is from the transactionListLine", summary)}
              {summary[0]?.value}
            </span>
          </div>
          <div className="flex flex-col">
            {summary.slice(1, -1).map(({ name, value }, idx) => {
              return (
                <div className="text-xs inline-flex md:text-sm" key={idx}>
                  {value}
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex items-start justify-between flex-col gap-2">
          {lines > 1 && hasMultiButton && (
            <div class="checkbox-style-1 place-self-end">
              <input id={`check-control-${item.targetid}`} type="checkbox" />
              <label
                htmlFor={`check-control-${item.targetid}`}
                className="block w-6 h-6"
              >
                {" "}
              </label>
            </div>
          )}
          <div
            style={{ borderColor: "#D0D0D0" }}
            className="-mr-7 border-r-0 px-4 py-1 rounded-l-3xl border text-semibold text-xs md:text-sm"
            dangerouslySetInnerHTML={{ __html: summary.slice(-1)[0].value }}
          />
        </div>
      </div>

      <div className="h-7"></div>

      {activeItem && (
        <>
          <div className="block min-w-full overflow-hidden w-full">
            <EditableTableRow
              item={activeItem}
              getInitialValues={getInitialValues}
              getObjectValue={getObjectValue}
              actions={actions}
              collapseSection={() => setActiveItem(null)}
            />

            {activeItem?.options &&
              (() => {
                let lineOptions = activeItem?.options;
                console.log("lineoptions are", lineOptions);
                return (
                  <div className="p-6 bg-gray-100 mt-4">
                    {lineOptions?.map(option => <InnerLineGrid updateBidOptions={updateBidOptions} lineOption={option} />)}
                  </div>
                );
              })()}
          </div>
        </>
      )}

      {!activeItem &&
      <div className="flex gap-2 justify-between mt-3 mb-1 lg:flex-row flex-col">
        <div className="flex gap-2">
          <ButtonStyle
            className="shadow-md bg-gray-100 p-2 px-3 rounded-md text-xs font-semibold flex items-center"
            onClick={() => activeItem ? setActiveItem(null) : getActiveItem(item)}
          >
            {processing ? (
              <>
                <span className="mr-2 text-xs font-medium text-black">
                  Loading
                </span>{" "}
                <Spinner width={16} height={16} fill="#aaaaaa" />
              </>
            ) : (
              <>
                <ToggleGridButtonIcon className="shadow w-4 h-4 rounded-full inline-grid place-items-center">
                  <ChevronDownIcon
                    width={16}
                    height={16}
                    fill={theme?.colors?.primary}
                  />
                </ToggleGridButtonIcon>
                <span className="ml-2 text-xs font-medium text-black">
                  Expand
                </span></>
            )}
          </ButtonStyle>
        </div>
        <div className="flex gap-2">
          {console.log("buttons", button)}
          {button?.map((obj, idx) => (
            <TransactionLineButton
              {...obj}
              {...{
                targetid,
                actions,
                item,
                headerControl: (item?.parent ?? item?.edit_header ?? [])[0],
                editable_controls:activeItem?.line_control_edit,
                lines:activeItem?.line_data
              }}
            />
          ))}
        </div>
      </div>
      }

    </GridStyle>
  );
};

const InnerLineGrid = (props) => {
  const [lineOption,setLineOption] = useState(null);
  const {updateBidOptions} = props

  useEffect(() => {
    setLineOption(props.lineOption)
  },[])

  return (
    <div className="flex justify-between w-full items-center px-5 bg-gray-200 py-2 mb-2 rounded-sm">
    {lineOption?.child_summary.map(({ value }) => (
      <span className="text-xs font-semibold">{value}</span>
    ))}
    <div className="flex gap-4">
      {lineOption?.button.map((item) => (
        <ButtonStyle2
          onClick={() =>
            updateBidOptions({
              options: lineOption,
              name: item.name,
              buttonItem:item
            })
          }
          className="bg-transparent py-1 hover:bg-red-500 shadow text-xs font-semibold hover:text-white px-4 border border-red-500 hover:border-transparent rounded"
        >
          {item.name}
        </ButtonStyle2>
      ))}
    </div>
    </div>
  )

}

export default TransactionListLine;

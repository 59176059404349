import React, { useEffect, useContext, useState } from "react";
import AppContext from "../contexts/AppContext";
import appFetch from "../appFetch";
import apiEndpoints from "../apiEndpoints";
import WithLoader from "./WithLoader";
import Spinner from "../loaders/Spinner";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import toast from "react-hot-toast";
import SavedTransactionList1 from "./SavedTransactionList1";
import VerticalSummary from "./VerticalSummary";
import useMenuUpdate from "../hooks/useMenuUpdate";
import { appendQueryToUri } from "../util/generalUtil";
import moment from "moment";

const MySwal = withReactContent(
  Swal.mixin({
    customClass: {
      confirmButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
      cancelButton:
        "text-white font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 bg-primary",
    },
    buttonsStyling: false,
  })
);

function ListCartSource({
  currStep,
  setLoading,
  nextPage,
  formData,
  setFormData,
  target_id,
  previousPage,
  actionButton,
  button_action,
  formDefaultValues,
  formInitialValues,
  edit,
  updateRecord,
  submit,
}) {
  console.log(nextPage);
  console.log("the setformdata", setFormData);
  const { context } = useContext(AppContext);
  const lastStep = nextPage === null;

  console.log("button_action", button_action, edit, submit);
  console.log({ formDefaultValues, formInitialValues });

  let initialValues = {};
  const { updateMenu } = useMenuUpdate();

  const [records, setRecords] = useState(null);
  const [saving, setSaving] = useState(false);
  const [fetching, setFetching] = useState(false);

  let recordOptions = (records ?? [])[0];

  const fetchListSource = () => {
    setFetching(true);

    let postData = {
      jsonString: JSON.stringify([formData]),
      menuId: context?.menuId.toString(),
      pageButtonActionId: target_id,
      buttonAction: actionButton,
    };
    appFetch
      .post(apiEndpoints.menuAction, {
        body: JSON.stringify(postData),
      })
      .then((res) => {
        let layout = res?.layout;
        if (Array.isArray(layout)) {
          console.log("layout records", layout);
          setRecords(layout);
        }
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        setFetching(false);
      });
  };

  useEffect(() => {
    fetchListSource();
    setRecords(null);
  }, [currStep.name]);

  const handlePostAsync = async (myVals) => {
    let data = await postData(myVals);
    console.log("result from server", data);
    if (data.constructor == {}.constructor) {
      updateMenu(data?.MENU_BADGE);
      let savedRecords =
        data?.layout?.children?.find(
          ({ type }) => type === "SavedRecordList1"
        ) ??
        data?.layout?.children?.find(
          ({ type }) => type === "SavedTransactionList1"
        );
      updateRecord(savedRecords);
    }
  };

  const postData = (values) => {
    return new Promise((resolve, reject) => {
      let postData = {
        jsonString: JSON.stringify([formData]),
        menuId: context?.menuId.toString(),
        pageButtonActionId: target_id.toString(),
        buttonAction: button_action,
      };

      console.log("postData", postData);
      // return;

      setSaving(true);
      appFetch
        .post(apiEndpoints.menuAction, {
          body: JSON.stringify(postData),
        })
        .then((res) => {
          let { ERROR_MESSAGE: errMsg, ERROR_NUMBER: errNo } = res;

          MySwal.fire({
            text: errMsg,
            icon: errNo === 0 ? "success" : "error",
          });

          if (errNo !== 0) {
            reject(errMsg);
            // throw new Error(errMsg);
          }
          // setRecords(null);
          resolve(res);
        })
        .catch((err) => {
          toast.error(err.message ?? "an error occured!");
        })
        .finally(() => setSaving(false));
      // return result;
    });
  };

  console.log("initialValues", initialValues);

  const gridType = recordOptions?.gridtype;
  let templateName = "payroll"

  const exportData = async () => {
    setLoading(true)
      let postData = {    
          "urlMenu": null,
          "layoutId": context?.menuId.toString(),
          "targetId": recordOptions?.targetid,
          "target": recordOptions?.target,
          "schemaId": null,
          "txnGroup": null,
          "parentDropValue":null,
          "drillId": null,
          "targetName":null,
          "targetType":null,
          "outputType":"DATASET"
        }

    let token = localStorage.getItem("token") ?? ""
    fetch(appendQueryToUri(apiEndpoints.downloadData,postData),{
        headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
          }
      })
      .then(response => {
        if (!response.ok) throw new Error('Something went wrong! could not download file.') 
        return response.blob()
      })
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${templateName}-${moment().format('YYYY-MM-DD_h:msa')}.xlsx`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a)
        URL.revokeObjectURL(url)
      })
      .catch(e => {
        MySwal.fire({
          text: e.message,
          icon: "error",
        });
      })
      .finally(e => setLoading(false))
}

  return (
    <div>
      {!fetching && (records || []).length == 0 && (
        <div className="mt-10 grid place-items-center">
          <p className="text-center">
            No records found. Something is not right. <br />{" "}
            <span className="text-primary text-sm">
              Please contact your system administrator
            </span>
          </p>
        </div>
      )}
      {fetching && (
        <div className="grid place-items-center w-full h-20">
          <Spinner width={48} height={48} fill="#cccccc" />
        </div>
      )}
      {!fetching && records && gridType === "horizontal" ? (
        <SavedTransactionList1 options={records} setLoading={setLoading} />
      ) : (
        <VerticalSummary options={records} />
      )}

      <div className="flex gap-2 justify-end mt-3">
        {!fetching && previousPage && (
          <button
            type="button"
            onClick={previousPage}
            className="justify-self-end bg-primary py-2 px-5 text-white rounded-md"
          >
            &#8592;
          </button>
        )}
        {!fetching && records && (
          <>
          <button
            type="button"
            className="justify-self-end bg-primary py-2 px-5 text-white rounded-md capitalize"
            onClick={async () => {
              if (lastStep) {
                let inputValues = { ...formData };
                handlePostAsync(inputValues);
                return;
              }

              if (currStep?.nextButtonAction) {
                nextPage(formData);
                return;
              }
              postData();
            }}
          >
            {nextPage ? (
              <> &#8594; </>
            ) : saving ? (
              <Spinner />
            ) : (
              <> {button_action?.toLowerCase() ?? "&#8594;"}</>
            )}
          </button>
          <button  type="button"
            className="justify-self-end bg-primary py-2 px-5 text-white rounded-md capitalize"
            onClick={exportData}>
              Export
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default WithLoader(ListCartSource);

import React, { Fragment, useState } from "react";
import icons from "../icons";
import { Menu, Transition, Tab } from "@headlessui/react";
import NotificationModal from "./NotificationModal";
import styled from "styled-components";
import SettingsModal, { SettingsForm } from "./SettingsModal";
import ProfileModal from "./ProfileModal";
import profilePicture from "../assets/images/profile-image.png";
import AccountModal from "./AccountModal";

const Dialog = styled.div`
  width: ${({ type }) =>
    ["Notifications", "Profile"].includes(type) ? "368px" : "auto"};
`;

const topIcons = {
  "bell":"NotificationIcon",
  "user":"UserIcon",
  "admin":"SettingIcon"
}


const Dropdown = ({ icon, type, options, loadForm }) => {
  return (
    <Menu as="div">
      <Menu.Button className="inline-flex items-center justify-between w-full rounded-md 0 px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
        {React.createElement(icon, {
          fill: "#000000",
        })}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Menu.Items
          as={Dialog}
          type={type}
          className="origin-top-right absolute transform left-1/2 -translate-x-1/2 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <>
            {type === "Notifications" && (
              <NotificationModal options={options} />
            )}
            {/* {type === "Profile" && <ProfileModal options={options} />} */}
            {type === "Settings" && (
              <SettingsModal options={options} loadForm={loadForm} />
            )}
            {type === "Profile" && (
              <AccountModal options={options} loadForm={loadForm} />
            )}
          </>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

function TopNavMenuList1({ options }) {
  const [form, setForm] = useState(null);

  const loadForm = (item) => {
    console.log("item", item);
    setForm(item);
  };

  return (
    <>
      {form && (
        <SettingsForm item={form} updateFormState={() => loadForm(null)} />
      )}
      <div className="grid justify-center place-items-center grid-flow-col gap-4 relative z-10">
        {options &&
          options.map(
            ({ name, icon, options }, idx) =>
              icons[topIcons[icon]] && (
                <Dropdown
                  key={idx}
                  loadForm={loadForm}
                  icon={icons[topIcons[icon]]}
                  type={name}
                  options={options}
                />
              )
          )}
      </div>
    </>
  );
}

// function TopNavMenuList1({options}) {
//     return (
//         <div className='grid justify-center place-items-center grid-flow-col gap-16'>
//             {options && options.map(({icon},idx) => <div key={idx}>
//                 {icons[icon] &&
//                 React.createElement(icons[icon], {
//                     fill:"#000000"
//                 })}
//             </div>)}
//         </div>
//     );
// }

export default TopNavMenuList1;

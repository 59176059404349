import React, { useEffect, useState } from 'react';
import styled from "styled-components"
import { ChevronLeftIcon,ChevronRightIcon } from '@heroicons/react/solid'
import { Transition } from '@headlessui/react';
import classNames from 'classnames';

const RightBar = styled.div`
    background-color:#FBFBFB;
    box-shadow: -1px 0 4px rgb(0 0 0 / 10%)
`

const RightBarToggler = styled.div`
    background: #FCFCFC;
`
//     box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);

function RightArea1(props) {
    const [expanded,setExpanded]  = useState(false);
    const [isShowing, setIsShowing] = useState(false)


    return (
        // <div className={`lg:max-w-sm w-full transition-transform min-h-screen h-full right-sidebar lg:relative fixed right-0 top-0 transform lg:transform-none border-l-2 lg:border-none z-20 ${expanded ? "-translate-x-rb" : "translate-x-rb"}` }>
        //     <RightBarToggler className="z-10 rounded-full lg:hidden w-16 h-16 absolute left-rb-1/2 transform -translate-x-1/2 translate-y-16 inline-grid place-items-center" onClick={() => setExpanded(!expanded)}>
        //         {expanded ? <ChevronRightIcon width={24} height={24} /> : <ChevronLeftIcon width={24} height={24} /> }
        //     </RightBarToggler>
        //     <RightBar className="right-sidebar relative h-full overflow-auto px-8 py-8 pb-20 lg:pb-0">
        //         {props.children}
        //     </RightBar>
        // </div>

    //     <Transition
    //     show={isShowing}
    //     enter="transition duration-300 transform"
    //     enterFrom="-translate-x-full"
    //     enterTo="translate-x-0"
    //     leave="transition duration-300 transform"
    //     leaveFrom="translate-x-0"
    //     leaveTo="-translate-x-full"
    //   >
    //      <div className="lg:max-w-sm w-full transition-transform min-h-screen h-full right-sidebar lg:relative fixed right-0 top-0 transform border-l-2 lg:border-none z-20 w-rb">
    //         <RightBarToggler className="cursor-pointer z-10 rounded-full w-12 h-12 absolute bottom-8 transform -translate-x-1/2 inline-grid place-items-center " onClick={() => setIsShowing(!isShowing)}>
    //             {isShowing ? <ChevronRightIcon width={24} height={24} /> : <ChevronLeftIcon width={24} height={24} /> }
    //         </RightBarToggler>
    //         <RightBar className="right-sidebar relative h-full overflow-auto px-8 py-8 pb-20 lg:pb-0">
    //             {props.children}
    //         </RightBar>
    //     </div>
    //   </Transition>

    <div className=''>
        <RightBarToggler className="group cursor-pointer z-10 w-20 h-12 fixed lg:absolute bottom-8 transform flex items-center right-0 transform translate-x-8 hover:translate-x-6 transition-transform rounded-full shadow-lg border" onClick={() => setIsShowing(!isShowing)}>
            <ChevronLeftIcon width={24} height={24} className={classNames("transition-transform ml-3 group-hover:rotate-180",{"rotate-180":isShowing, "rotate-0":!isShowing})}/> 
        </RightBarToggler>
        <Transition
            show={isShowing}
            enter="transition duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0 h-full"
            leave="transition duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
        >
            <div className="lg:max-w-sm w-screen transition-transform min-h-screen h-full right-sidebar lg:relative fixed right-0 top-0 transform border-l-2 lg:border-none z-20">
                <RightBar className="right-sidebar relative h-full overflow-auto px-8 py-8 pb-20 lg:pb-0">
                    {props.children}
                </RightBar>
            </div>
        </Transition>
    </div>
   

      
      
       
       
    );
}

export default RightArea1;
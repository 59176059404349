import React from "react";
import layout from "./layouts"
import { useNavigate } from 'react-router-dom';


function renderLayout(config) {
  if (typeof layout[config?.type] !== "undefined") {
    return React.createElement(
      layout[config.type],
      null,
      config.children
    );
  }
}

export default renderLayout;
import React, {Fragment, useEffect, useState } from 'react';
import Preview from './Preview';
import { Dialog, Transition } from '@headlessui/react'

const PreviewTransaction = ({name,isOpen,setIsOpen,data,handleSubmit, handlePrint}) => {
    // console.log("data from close modal page", isOpen,setIsOpen,data,handleSubmit, handlePrint);

  return (
    <Dialog
    open={isOpen}
    onClose={() => {}}
    className="fixed z-10 inset-0 overflow-y-auto"
  >
    <div className="flex items-center justify-center min-h-screen h-full">
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
      <div className="relative bg-white mx-auto my-10">
            <Preview {...{name,data,handlePrint,handleSubmit,setIsOpen}}/>
      </div>
    </div>
  </Dialog>
  )
}
export default PreviewTransaction;
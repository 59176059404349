import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router, Route,Switch, useHistory} from "react-router-dom"
import {createPortal} from "react-dom"

import * as themes from './theme/schema.json';
import { getFromLS, setToLS } from './util/storage';
import Login from "./routes/Login"
import AppContext from './contexts/AppContext';

import { Toaster, resolveValue } from 'react-hot-toast';
import ResetPassword from './routes/ResetPassword';
import ForgotPassword from './routes/ForgotPassword'
import Login2 from './routes/Login2';
import appFetch from './appFetch';
import apiEndpoints from './apiEndpoints';
import NotAllowed from './routes/NotAllowed';

import DateNotificationList1 from "./components/DateNotificationList1"
import { SubscriberProvider, useSubscriber } from './hooks/useSubscriber';
import { useParams } from 'react-router-dom';
import { DefaultSubscriber } from './constants/variables';

setToLS('all-themes', themes.default);

if (process.env.NODE_ENV === 'production'){
  console.log = function() {}
}

const WithThemeWrapper = WrappedComponent => {

  const HOC = props => {
    const {subs} = useSubscriber()
    const localTheme = getFromLS("theme");
    const localThemeName = localTheme?.name?.toLowerCase() || subs

    const savedTheme = localThemeName == subs ? localTheme : null;
    const [theme, setTheme] = useState(savedTheme)

    useEffect(() => {
      appFetch.get(`${apiEndpoints.getAppCache}?subs=${subs}`)
          .then(res => {
              console.log("subscriber app data", res)
              setToLS("theme", res)
              setTheme(res);
          })
    }, [subs])

    return (
      <>{theme && <WrappedComponent theme={theme} subs={subs} {...props} />}</>
    )
  }

  return HOC;
}

const RedirectToLogin = () => {
  const history = useHistory()
  history.push("/login")
  return <></>
}

const AppLandingPage = () => {
  const {subs} = useSubscriber();
  const history = useHistory()

  const localTheme = getFromLS("theme");
  const subscriberUrl = localTheme?.url?.toLowerCase();
  const isSubscriber = subscriberUrl == subs

  history.push(`/${subs}/${isSubscriber ? "home" : "login"}`);
  return (<></>)
}

const Application = () => {
  const [context,setContext] = useState({});
  const hasDefaultSubscriber = DefaultSubscriber != null
  return (
        <AppContext.Provider value={{
          context,
          setContext
          }}>
          <SubscriberProvider>
          <Router>
              <Switch>
                
              {!hasDefaultSubscriber && <Route exact path="/:subs/login" component={Login} />}
              {!hasDefaultSubscriber &&  <Route exact path="/:subs/reset-password" component={ResetPassword} />}
               {!hasDefaultSubscriber &&  <Route exact path="/:subs/forgot-password" component={ForgotPassword} />}
               {!hasDefaultSubscriber &&  <Route exact path="/:subs/:page" component={App}/>}
               {!hasDefaultSubscriber &&  <Route exact path="/:subs" component={AppLandingPage}/>}
               {!hasDefaultSubscriber &&  <Route path="/" component={NotAllowed}/> }
                
                
                {hasDefaultSubscriber &&  <Route exact path="/:subs/:page" component={RedirectToLogin} />}
                {hasDefaultSubscriber && <Route exact path="/login" component={Login} />}
                {hasDefaultSubscriber && <Route exact path="/reset-password" component={ResetPassword} />}
                {hasDefaultSubscriber && <Route exact path="/forgot-password" component={ForgotPassword} />}
                {hasDefaultSubscriber && <Route path="/" component={App}/> }
                
            </Switch>
          </Router>
        {
            createPortal(
              <Toaster
              position="top-center"
              reverseOrder={false}
              gutter={8}
              containerClassName=""
              toastOptions={{
                // Define default options
                className: '',
                duration: 5000,
                style: {
                  zIndex: 9999999,
                }}}
              />,
              document.getElementById("toast-root")
          )
        }
        </SubscriberProvider>
      </AppContext.Provider>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Application />      
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
